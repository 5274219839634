export type Country = {
  PAY_ID: string;
  PAY_CODE_ISO2: string;
};

export const SUPPORTED_COUNTRIES: Country[] = [
  {
    PAY_ID       : '18',
    PAY_CODE_ISO2: 'BE',
  },
  {
    PAY_ID       : '51',
    PAY_CODE_ISO2: 'CG',
  },
  {
    PAY_ID       : '75',
    PAY_CODE_ISO2: 'FR',
  },
  {
    PAY_ID       : '84',
    PAY_CODE_ISO2: 'DE',
  },
  {
    PAY_ID       : '109',
    PAY_CODE_ISO2: 'IT',
  },
  {
    PAY_ID       : '144',
    PAY_CODE_ISO2: 'MA',
  },
  {
    PAY_ID       : '153',
    PAY_CODE_ISO2: 'NC',
  },
  {
    PAY_ID       : '193',
    PAY_CODE_ISO2: 'SN',
  },
  {
    PAY_ID       : '203',
    PAY_CODE_ISO2: 'ES',
  },
  {
    PAY_ID       : '210',
    PAY_CODE_ISO2: 'CH',
  },
  {
    PAY_ID       : '213',
    PAY_CODE_ISO2: 'TH',
  },
  {
    PAY_ID       : '218',
    PAY_CODE_ISO2: 'AE',
  },
  {
    PAY_ID       : '227',
    PAY_CODE_ISO2: 'EG',
  },
  {
    PAY_ID       : '228',
    PAY_CODE_ISO2: 'GB',
  },
];
