// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: inherit;
  height: inherit;
}
:host.warning {
  fill: var(--ion-color-warning);
}
:host.primary {
  fill: var(--way-style-brand);
}
:host .remix {
  width: inherit;
  height: inherit;
}
:host .remix.blue {
  fill: var(--way-style-brand-contrast);
}
:host .remix.m1 {
  fill: var(--way-color-m1);
}
:host .remix.d1 {
  fill: var(--way-color-d1);
}
:host .remix.d2 {
  fill: var(--way-color-d2);
}
:host .remix.l2 {
  fill: var(--way-color-l2);
}
:host .remix.regular {
  fill: var(--way-color-brand);
}
@media (prefers-color-scheme: dark) {
  :host .remix.blue {
    fill: var(--way-style-txt-primary);
  }
  :host .remix.regular {
    fill: var(--way-color-brand);
  }
}
:host .remix.danger {
  fill: var(--ion-color-danger);
}
:host .remix.white {
  fill: white;
}
:host .remix.red {
  fill: red;
}
:host .remix.green {
  fill: green;
}
:host .remix.purple {
  fill: var(--way-color-brand);
}
:host .remix.noteChauffeur {
  fill: var(--ion-color-danger);
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/icons/remix-icon/remix-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;AACF;AACE;EACE,8BAAA;AACJ;AAEE;EACE,4BAAA;AAAJ;AAGE;EACE,cAAA;EACA,eAAA;AADJ;AAIE;EACE,qCAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAKE;EACE,yBAAA;AAHJ;AAKE;EACE,yBAAA;AAHJ;AAKE;EACE,yBAAA;AAHJ;AAME;EACE,4BAAA;AAJJ;AAOE;EACE;IACA,kCAAA;EALF;EAQE;IACE,4BAAA;EANJ;AACF;AAQE;EACE,6BAAA;AANJ;AASE;EACE,WAAA;AAPJ;AAUE;EACE,SAAA;AARJ;AAWE;EACE,WAAA;AATJ;AAYE;EACE,4BAAA;AAVJ;AAaE;EACE,6BAAA;AAXJ","sourcesContent":[":host {\n  width: inherit;\n  height: inherit;\n\n  &.warning {\n    fill: var(--ion-color-warning);\n  }\n\n  &.primary {\n    fill: var(--way-style-brand);\n  }\n\n  .remix {\n    width: inherit;\n    height: inherit;\n  }\n\n  .remix.blue {\n    fill: var(--way-style-brand-contrast);\n  }\n\n  .remix.m1 {\n    fill: var(--way-color-m1);\n  }\n  .remix.d1 {\n    fill: var(--way-color-d1);\n  }\n  .remix.d2 {\n    fill: var(--way-color-d2);\n  }\n  .remix.l2 {\n    fill: var(--way-color-l2)\n  }\n\n  .remix.regular {\n    fill: var(--way-color-brand);\n  }\n\n  @media (prefers-color-scheme: dark) {\n    .remix.blue {\n    fill: var(--way-style-txt-primary);\n    }\n\n    .remix.regular {\n      fill: var(--way-color-brand);\n    }\n  }\n  .remix.danger {\n    fill: var(--ion-color-danger);\n  }\n\n  .remix.white {\n    fill: white;\n  }\n\n  .remix.red {\n    fill: red;\n  }\n\n  .remix.green {\n    fill: green;\n  }\n\n  .remix.purple {\n    fill: var(--way-color-brand);\n  }\n\n  .remix.noteChauffeur {\n    fill: var(--ion-color-danger);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
