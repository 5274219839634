import { CGenMissionRow, CComServiceRow, CComCategorieRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { Jaf } from '@way-lib/common/services/jaf/jaf';

export class CComFraisMissionRow extends JafRow {
  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_ID%',
    class: 'Id',
  })
  public FMI_ID;

  @WayChamp({
    label  : '%LAB_COM_FRAIS_MISSION_MIS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Mission',
    indexed: true,
  })
  public FMI_MIS_ID: CGenMissionRow;

  @WayChamp({
    label  : '%LAB_COM_FRAIS_MISSION_SER_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_Service',
    indexed: true,
  })
  public FMI_SER_ID: CComServiceRow;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_LIBELLE%',
    class: 'Libelle',
  })
  public FMI_LIBELLE: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_QTE%',
    class: 'Quantite',
  })
  public FMI_QTE: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_DESCRIPTION%',
    class: 'Textarea',
  })
  public FMI_DESCRIPTION: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_VENTE_HT%',
    class: 'Montant',
  })
  public FMI_ACHAT_HT: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_POURCENTAGE_REMISE%',
    class: 'Pourcentage',
  })
  public FMI_POURCENTAGE_REMISE: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_VENTE_HT%',
    class: 'Montant',
  })
  public FMI_VENTE_HT: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_TVA%',
    class: 'Pourcentage',
  })
  public FMI_TVA: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_KM_INCLUS%',
    class: 'Quantite',
  })
  public FMI_KM_INCLUS: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_HEURE_INCLUS%',
    class: 'Quantite',
  })
  public FMI_HEURE_INCLUS: any;

  @WayChamp({
    label: '%LAB_COM_FRAIS_MISSION_TVA_CODE%',
    class: 'Texte',
  })
  public FMI_TVA_CODE: any;

  getHtPriceWithDiscount(): number {
    const itemPrice = this.FMI_VENTE_HT;
    const discount  = this.FMI_POURCENTAGE_REMISE;

    if (discount === 0) return itemPrice;

    return itemPrice - (itemPrice * discount) / 100;
  }

  get tvaPrice(): number {
    const fullPrice = this.getHtPriceWithDiscount() * (1 + this.FMI_TVA / 100);
    const tvaAmount = fullPrice - this.getHtPriceWithDiscount();
    return +tvaAmount.toFixed(2);
  }

  get ttcPrice(): number {
    const htPrice = this.getHtPriceWithDiscount();
    const vat     = 1 + this.FMI_TVA / 100;
    const sum     = +Jaf.roundCurrency(htPrice * vat);

    return sum;
  }

  get isMainCost(): boolean {
    const categoryId =
      this.FMI_SER_ID?.SER_CAT_ID instanceof CComCategorieRow
        ? this.FMI_SER_ID?.SER_CAT_ID?.CAT_ID
        : this.FMI_SER_ID?.SER_CAT_ID;
    return categoryId === CComCategorieRow.CAT_MISSION;
  }

  get discount(): number {
    return Number(this.FMI_POURCENTAGE_REMISE || 0);
  }
}
