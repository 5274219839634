// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
  text-decoration: underline;
  cursor: pointer;
}

.ion-text-wrap {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-underline/way-underline.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,eAAA;AACF;;AACA;EACE,eAAA;AAEF","sourcesContent":[".link {\n  text-decoration: underline;\n  cursor: pointer;\n}\n.ion-text-wrap{\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
