// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  text-align: center;
  margin: 0 5px;
  --padding-start: 10px !important;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-number-input/way-number-input.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,gCAAA;AACF","sourcesContent":["ion-input {\n  text-align: center;\n  margin: 0 5px;\n  --padding-start: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
