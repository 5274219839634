// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 45px;
  --color-checked: var(--ion-color-primary, #black);
  border-bottom: 1px solid var(--way-style-page-border);
}
:host ion-segment-button {
  --indicator-height: 3px;
  height: 45px;
  min-height: 40px;
}
:host ion-segment-button ion-label {
  font-size: 1.2em;
  font-weight: 500;
  color: #757e9d;
  text-transform: capitalize;
  letter-spacing: initial;
}
:host ion-segment-button::part(indicator-background) {
  margin: auto;
  width: 74%;
  border-radius: 30px;
}
:host .segment-button-checked ion-label {
  color: var(--color-checked);
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-segment-scroll/way-segment-scroll.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iDAAA;EACA,qDAAA;AACF;AACE;EACE,uBAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACI;EACE,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,0BAAA;EACA,uBAAA;AACN;AAEI;EACE,YAAA;EACA,UAAA;EACA,mBAAA;AAAN;AAIE;EACE,2BAAA;AAFJ","sourcesContent":[":host {\n  height: 45px;\n  --color-checked: var(--ion-color-primary, #black);\n  border-bottom: 1px solid var(--way-style-page-border);\n\n  ion-segment-button {\n    --indicator-height: 3px;\n    height: 45px;\n    min-height: 40px;\n\n    ion-label {\n      font-size: 1.2em;\n      font-weight: 500;\n      color: #757e9d;\n      text-transform: capitalize;\n      letter-spacing: initial;\n    }\n\n    &::part(indicator-background) {\n      margin: auto;\n      width: 74%;\n      border-radius: 30px;\n    }\n  }\n\n  .segment-button-checked ion-label {\n    color: var(--color-checked);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
