// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  border-radius: 5px;
  background-color: #fff !important;
  color: #000;
  margin: 0 auto;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-input-code/way-input-code.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iCAAA;EACA,WAAA;EACA,cAAA;EACA,UAAA;AACF","sourcesContent":["ion-input {\n  border-radius: 5px;\n  background-color: #fff !important;\n  color: #000;\n  margin: 0 auto;\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
