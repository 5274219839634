// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host a {
  color: inherit;
  text-decoration: none;
}
:host a.sms, :host a.tel {
  color: var(--ion-color-way-partner);
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 0.2em;
  margin-left: 0.5em;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-clickable-phone-number/way-clickable-phone-number.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,qBAAA;AAAJ;AAEE;EACE,mCAAA;EACA,gBAAA;EACA,eAAA;EACA,oBAAA;EACA,kBAAA;AAAJ","sourcesContent":[":host {\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n  a.sms,a.tel {\n    color: var(--ion-color-way-partner);\n    font-size: 1.5em;\n    cursor: pointer;\n    border-radius:0.2em;\n    margin-left: 0.5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
