// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  height: 55px;
  width: 97%;
}

ion-input {
  background: var(--way-style-blockbg);
  border: 1px solid var(--way-style-block-border);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 10px;
}
ion-input.sc-ion-input-md-h:not(.legacy-input), ion-input.sc-ion-input-ios-h:not(.legacy-input) {
  min-height: 36px;
}

ion-icon {
  color: var(--way-color-brand);
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-input/way-input.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;AACF;;AAEA;EACE,oCAAA;EACA,+CAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EAEE,gBAAA;AAAJ;;AAIA;EACE,6BAAA;AADF","sourcesContent":["ion-item {\n  height: 55px;\n  width: 97%;\n}\n\nion-input {\n  background: var(--way-style-blockbg);\n  border: 1px solid var(--way-style-block-border);\n  box-sizing: border-box;\n  border-radius: 5px;\n  margin: 10px;\n\n  &.sc-ion-input-md-h:not(.legacy-input),\n  &.sc-ion-input-ios-h:not(.legacy-input) {\n    min-height: 36px;\n  }\n}\n\nion-icon {\n  color: var(--way-color-brand);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
