// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: var(--way-color-l1);
}

ion-item {
  width: 100%;
  background-color: inherit;
  border-bottom: 1px var(--ion-color-waynium-light1) solid;
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-item/way-item.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;AACF;;AACA;EACE,WAAA;EACA,yBAAA;EACA,wDAAA;EACA,kBAAA;AAEF","sourcesContent":[":host {\n  background-color: var(--way-color-l1);\n}\nion-item {\n  width: 100%;\n  background-color: inherit;\n  border-bottom: 1px var(--ion-color-waynium-light1) solid;\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
