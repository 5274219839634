import { Component, EventEmitter, Input, Output, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { parsePhoneNumberWithError, AsYouType, CountryCode } from 'libphonenumber-js';
import { Country, SUPPORTED_COUNTRIES } from './supported-countries';

@Component({
  selector   : 'way-input-mobile',
  templateUrl: './way-input-mobile.component.html',
  styleUrls  : ['./way-input-mobile.component.scss'],
})
export class WayInputMobileComponent implements OnInit {
  @Input() size: string = '';

  @Input() phoneNumber!: string;

  @Input() placeholder = '06 12 34 56 78';

  @Input() variant?: 'filled' | 'outline' = 'outline';

  @Output() phoneData = new EventEmitter<{ internationalFormat: string; normalFormat: string }>();

  @Output() isPhoneNumberValid = new EventEmitter<boolean>();

  @Output() countryCode = new EventEmitter<string>();

  selectedCountry: CountryCode = 'FR';

  isInvalid = false;

  phoneForm!: FormGroup;

  asYouType!: AsYouType;

  isDropdownOpen = false;

  supportedCountries: Country[] = SUPPORTED_COUNTRIES;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;

    const dropdownElement = clickedElement.closest('.custom-dropdown');

    if (!dropdownElement) {
      this.isDropdownOpen = false;
    }
  }

  ngOnInit() {
    this.phoneForm = new FormGroup({
      phone: new FormControl(this.phoneNumber, [Validators.required]),
    });

    this.asYouType = new AsYouType(this.selectedCountry);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectCountry(countryCode: string) {
    this.selectedCountry = countryCode as CountryCode;
    this.countryCode.emit(countryCode);
    this.isDropdownOpen = false;
    this.asYouType      = new AsYouType(countryCode as CountryCode);

    const currentValue = this.phoneForm.get('phone')?.value;

    if (currentValue) {
      this.onPhoneInput({ target: { value: currentValue } } as any);
    }
  }

  onPhoneInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    this.asYouType = new AsYouType(this.selectedCountry);

    try {
      const phoneNumber = parsePhoneNumberWithError(value, this.selectedCountry);
    
      const isValid  = phoneNumber.isValid();
      this.isInvalid = !isValid;

      if (isValid) {
        this.phoneData.emit({
          internationalFormat: phoneNumber.formatInternational(),
          normalFormat       : phoneNumber.formatNational(),
        });

        this.isPhoneNumberValid.emit(true);
      } else {
        this.emitEmptyData();
      }
    } catch (error) {
      this.emitEmptyData();
    }
  }

  private emitEmptyData() {
    this.phoneData.emit({ internationalFormat: '', normalFormat: '' });
    this.isPhoneNumberValid.emit(false);
  }
}
