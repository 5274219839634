// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  color: #FA2F02;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-error/way-error.component.scss"],"names":[],"mappings":"AAAA;EAEE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EAEA,aAAA;EACA,uBAAA;EAEA,cAAA;EAEA,mBAAA;AAHF","sourcesContent":[".error {\n\n  font-family: Work Sans;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 1.2;\n\n  display: flex;\n  justify-content: center;\n\n  color: #FA2F02;\n\n  margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
