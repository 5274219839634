import { CGenTypeVehiculeRow, CGdsModeleRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenVoitureRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_VOITURE_ID%',
    class: 'Id',
  })
  public VOI_ID;

  @WayChamp({
    label  : '%LAB_GEN_VOITURE_TVE_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_TypeVehicule',
  })
  public VOI_TVE_ID: CGenTypeVehiculeRow;

  @WayChamp({
    label  : '%LAB_GEN_VOITURE_MOE_ID%',
    class  : 'Liaison',
    liaison: 'C_Gds_Modele',
  })
  public VOI_MOE_ID: CGdsModeleRow;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_PAR_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_Partenaire',
  })
  public VOI_PAR_ID;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_LIBELLE%',
    class: 'Libelle',
  })
  public VOI_LIBELLE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_MODELE%',
    class: 'Texte',
  })
  public VOI_MODELE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_PLACE%',
    class: 'Quantite',
  })
  public VOI_PLACE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_ENTREE%',
    class: 'Date',
  })
  public VOI_DATE_ENTREE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_BADGE%',
    class: 'Texte',
  })
  public VOI_NUMERO_BADGE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_CODE_INTERNE%',
    class: 'Texte',
  })
  public VOI_CODE_INTERNE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_GAR_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_Garage',
  })
  public VOI_GAR_ID;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_REVISION%',
    class: 'Date',
  })
  public VOI_DATE_REVISION;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_KILOMETRAGE%',
    class: 'Quantite',
  })
  public VOI_KILOMETRAGE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_CONTROLE_TECHNIQUE%',
    class: 'Date',
  })
  public VOI_DATE_CONTROLE_TECHNIQUE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_CONTROLE_TECHNIQ%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_CONTROLE_TECHNIQ;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_VTC%',
    class: 'Texte',
  })
  public VOI_NUMERO_VTC;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_NUMERO_VTC%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_NUMERO_VTC;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_ETHYLOTEST%',
    class: 'Texte',
  })
  public VOI_NUMERO_ETHYLOTEST;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_ETHYLOTEST%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_ETHYLOTEST;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_EXTINCTEUR%',
    class: 'Texte',
  })
  public VOI_NUMERO_EXTINCTEUR;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_EXTINCTEUR%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_EXTINCTEUR;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_CARTE_CARBURANT%',
    class: 'Texte',
  })
  public VOI_NUMERO_CARTE_CARBURANT;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_CARTE_CARBURANT%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_CARTE_CARBURANT;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_PHARMACIE%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_PHARMACIE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_LIMITEUR_VITESSE%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_LIMITEUR_VITESSE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_CHRONO%',
    class: 'Texte',
  })
  public VOI_NUMERO_CHRONO;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_CHRONO%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_CHRONO;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_BADGE_AUTOROUTE%',
    class: 'Texte',
  })
  public VOI_NUMERO_BADGE_AUTOROUTE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_BADGE_AUTOROUTE%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_BADGE_AUTOROUTE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_NUMERO_GEOLOC%',
    class: 'Texte',
  })
  public VOI_NUMERO_GEOLOC;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_GEOLOC%',
    class: 'Date',
  })
  public VOI_DATE_VALIDITE_GEOLOC;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_INFOS%',
    class: 'Textarea',
  })
  public VOI_INFOS;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_CARTE_GRISE%',
    class: 'Fichier',
  })
  public VOI_CARTE_GRISE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_TAUX_CO2%',
    class: 'Quantite',
  })
  public VOI_TAUX_CO2;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_GEOLOC_ID%',
    class: 'Texte',
  })
  public VOI_GEOLOC_ID;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_VITESSE%',
    class: 'Quantite',
  })
  public VOI_VITESSE;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_LAT%',
    class: 'Quantite',
  })
  public VOI_LAT;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_LNG%',
    class: 'Quantite',
  })
  public VOI_LNG;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_TRI%',
    class: 'Tri',
  })
  public VOI_TRI;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_FLAG_PLANNING%',
    class: 'Flag',
  })
  public VOI_FLAG_PLANNING;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_FLAG_TPMR%',
    class: 'Quantite',
  })
  public VOI_FLAG_TPMR;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_ETAT%',
    class: 'Etat',
  })
  public VOI_ETAT;

  get fullLabel(): string {
    if (!this.VOI_MOE_ID) return this.VOI_MODELE;

    const brand = this.VOI_MOE_ID?.MOE_MAR_ID?.MAR_LIBELLE || '';
    const model = this.VOI_MOE_ID?.MOE_LIBELLE || '';
    const gamme = this.VOI_MOE_ID?.MOE_GAMME || '';

    const labels = [brand, model, gamme].filter(Boolean);

    return labels.join(' ');
  }
}
