// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-top: 1.3em;
  width: 100%;
}
:host ion-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  position: relative;
}
:host ion-title remix-icon {
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 17px;
  fill: var(--way-style-txt-tertiary);
  margin-left: 0.25em;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-main-title/way-main-title.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACE,qBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,mCAAA;EACA,mBAAA;AACN","sourcesContent":[":host {\n  margin-top: 1.3em;\n  width: 100%;\n\n  ion-title {\n    font-size: 1.5rem;\n    font-weight: bold;\n    text-align: left;\n    position: relative;\n\n    remix-icon {\n      display: inline-block;\n      vertical-align: middle;\n      width: 17px;\n      height: 17px;\n      fill: var(--way-style-txt-tertiary);\n      margin-left: 0.25em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
