import { Observable } from 'rxjs';
import { JafConcept } from '@way-lib-jaf/concept';
import { CGdsEvenementChauffeurRow } from '@way-lib-jaf/rowLoader';
import { Jaf } from '@way-lib-jaf/jaf';
import * as rowLoader from '@way-lib-jaf/rowLoader';
import { InfoCompte } from '@way-lib/common/interfaces/limoChauffeur';
import { ClassEventId } from '@way-lib/common/constant';

export enum InternalNotification {
  NOTIFICATION_NO_REGISTER_ID = 'NOTIFICATION_NO_REGISTER_ID',
}

export type InternalNotificationTemplate = {
  type: InternalNotification;
  titreNotif: string;
  labelsNotif: string[];
};

export interface GetEventChauffeurResponse {
  [key: symbol]: {
    lastEveId: number;
    rowset: { [key: string]: any };
  };
  flagDispo: number;
}

export class CGdsEvenementChauffeur extends JafConcept {
  public primary = 'EVC_ID';

  protected name = 'nf_gds_evenementChauffeur';

  protected class = 'C_Gds_EvenementChauffeur';

  protected rowClass = 'CGdsEvenementChauffeurRow';

  protected trigramme = 'EVC';

  protected readonly EVENT_UPDATE_INS = 'updateInstallation';

  actionGetNotif = '/evc/get-notif-chauffeur';

  actionNotifLec = '/evc/set-notif-lecture';

  actionArchivageNotif = '/evc/set-archivage-notif';

  private _listeNotification: Array<CGdsEvenementChauffeurRow>;

  private _listeNotificationArchive: Array<CGdsEvenementChauffeurRow>;

  private _listeNotificationNombreNew: number;

  private _internalNotifications: Array<CGdsEvenementChauffeurRow> = [];

  private listeObserverNotification: Map<string, any> = new Map();

  loadNewEventCache = {
    timeout  : null,
    last_time: 0,
    encours  : false,
  };

  lastEveId = {};

  flagOn = false;

  flagExecute = false;

  filtres = [];

  actionGetEvent = '/evc/get-events-chauffeur-new';

  paramsToSend: {
    flagDispo?: string;
    geoloc?: string;
    lastEveId?: {};
  } = {};

  tempsEntreDeux = 1000;

  geolocActif = false;

  internalNotificationTemplates: InternalNotificationTemplate[] = [
    {
      type       : InternalNotification.NOTIFICATION_NO_REGISTER_ID,
      titreNotif : 'Numéro exploitant manquant',
      labelsNotif: ["Veuillez renseigner votre numéro d'exploitant"],
    },
  ];

  private lastEveIdPerField = new Map();

  reloadLastEveId() {
    this.cm.getDatabases().forEach((database) => {
      this.cm.storage.get(`eve.${database}.lastEveId`).then((lastEveId) => {
        this.lastEveId[database] = lastEveId > 0 ? lastEveId : 0;
        // console.info(`init eve avec ${database} pour lastEveId=${lastEveId}`);
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDispo(actif) {
    // fonction à faire sur le modele de way-d v1
  }

  initNotification() {
    this.cm.gds.post('evenementChauffeur', this.actionGetNotif, {}).subscribe(
      (rowsetEventsLimo) => {
        this.removeData();
        if (rowsetEventsLimo) {
          Object.keys(rowsetEventsLimo).forEach((database) => {
            const evcs = rowsetEventsLimo[database];
            evcs.forEach((eve) => {
              // eslint-disable-next-line no-param-reassign
              eve.EVE_PARAMS.database = database;
              const row               = new CGdsEvenementChauffeurRow(
                this.cm.getConcept('C_Gds_EvenementChauffeur'),
                {
                  EVC_ID               : eve.EVC_ID,
                  EVC_CEV_ID           : eve.EVE_CEV_ID,
                  EVC_DATE             : Jaf.getDate(eve.EVE_DATE),
                  EVC_PARAMS           : eve.EVE_PARAMS,
                  EVC_CPT_CLASS        : eve.CPT_CLASS,
                  EVC_PRIMARY          : eve.EVE_PRIMARY,
                  EVC_EVE_ID           : eve.EVE_ID,
                  EVC_FLAG_NOTIFICATION: eve.EVC_FLAG_NOTIFICATION,
                  EVC_LECTURE_DATE     : eve.EVC_LECTURE_DATE,
                },
                database,
              );
              this.setRow(row);
            });
          });
        }
        this.addBuild(['build_all', 'build_listesNotification']);
        this.askToSaveDataLocale();
      },
      () => {
        setTimeout(this.initNotification, 5000);
        console.error('impossible de communiquer avec le gds pour initNotification');
      },
    );
  }

  setLectureNotif(evcs) {
    const date   = Jaf.date2mysqltime(new Date());
    const evcIds = [];
    evcs.forEach((evc) => {
      evc.setterStorage('EVC_LECTURE_DATE', date);
      if (evc.EVC_PARAMS.misId) {
        const mission = this.cm
          .getConcept('C_Gen_Mission')
          .getRow(evc.EVC_PARAMS.misId, evc.database);
        if (mission) mission.launchBuildByRow(['build_listeNotifNonLue']);
      }

      evcIds.push(evc.EVC_ID);
    });
    this.addBuild(['build_all', 'build_listesNotification']);

    this.cm.gds.post('evenementChauffeur', this.actionNotifLec, { evcIds }).subscribe(
      () => {
        this.saveDataLocale();
      },
      () => {
        setTimeout(() => {
          this.setLectureNotif(evcs);
        }, 5000);
        console.warn(`impossible de communiquer avec le gds pour ${this.actionNotifLec}`);
      },
    );
  }

  setArchivageNotif(evcs) {
    const tabLecture = [];
    const evcIds     = [];
    evcs.forEach((evc) => {
      if (evc) {
        if (evc.empty('EVC_LECTURE_DATE')) {
          tabLecture.push(evc);
        }
        evcIds.push(evc.EVC_ID);
        evc.setterStorage('EVC_FLAG_NOTIFICATION', 3);
      }
    });
    if (tabLecture.length > 0) this.setLectureNotif(tabLecture);

    this.addBuild(['build_all', 'build_listesNotification']);
    this.cm.gds.post('evenementChauffeur', this.actionArchivageNotif, { evcIds }).subscribe(
      () => {
        this.saveDataLocale();
      },
      () => {
        setTimeout(() => {
          this.setArchivageNotif(evcs);
        }, 5000);
        console.warn(`impossible de communiquer avec le gds pour ${this.actionArchivageNotif}`);
      },
    );
  }

  getListeNotificationLectureByCle(database, misId) {
    return this.listeNotification.filter((evc: CGdsEvenementChauffeurRow) => {
      return (
        !evc.EVC_LECTURE_DATE &&
        evc.database === database &&
        ((evc.EVC_CPT_CLASS === 'C_Gen_Mission' && evc.EVC_PRIMARY === misId) ||
          (evc.EVC_CPT_CLASS === 'C_Gen_EtapePresence' && evc.EVC_PARAMS.EPR_MIS_ID === misId) ||
          (evc.EVC_CPT_CLASS === 'C_Gen_Presence' && evc.EVC_PARAMS.PRS_MIS_ID === misId))
      );
    });
  }

  getListeNotificationMission(database, misId) {
    // evenement non archivé non lue
    const cle        = `${database}-${misId}`;
    const observable = new Observable<Array<any>>((observer) => {
      this.listeObserverNotification.set(cle, observer);
      observer.next();
    });
    return observable;
  }

  build_listesNotification() {
    if (this._listeNotification === undefined) {
      this._listeNotification        = [];
      this._listeNotificationArchive = [];
      this.bindfilter(['EVC_ID', 'EVC_FLAG_NOTIFICATION'], ['build_listesNotification']);
    } else {
      this._listeNotification.splice(0, this._listeNotification.length);
      this._listeNotificationArchive.splice(0, this._listeNotificationArchive.length);
    }
    this._listeNotificationNombreNew = 0;
    this.all.forEach((row: CGdsEvenementChauffeurRow) => {
      if (row.EVC_PARAMS && row.EVC_PARAMS.titreNotif) {
        if (!row.EVC_LECTURE_DATE) {
          this._listeNotificationNombreNew += 1;
          this._listeNotification.push(row);
        } else if (row.EVC_FLAG_NOTIFICATION === 3) {
          this._listeNotificationArchive.push(row);
        } else {
          this._listeNotification.push(row);
        }
      }
    });

    this._internalNotifications.forEach((internalNotification) => {
      this._listeNotificationNombreNew += 1;
      this._listeNotification.push(internalNotification);
    });

    this._listeNotification.sort((a: CGdsEvenementChauffeurRow, b: CGdsEvenementChauffeurRow) => {
      return b.EVC_DATE.getTime() - a.EVC_DATE.getTime();
    });
    this._listeNotificationArchive.sort(
      (a: CGdsEvenementChauffeurRow, b: CGdsEvenementChauffeurRow) => {
        return b.EVC_DATE.getTime() - a.EVC_DATE.getTime();
      },
    );
    this.listeObserverNotification.forEach((observer) => {
      observer.next();
    });
  }

  get listeNotification() {
    if (this._listeNotification === undefined) {
      this.build_listesNotification();
    }
    return this._listeNotification;
  }

  get listeNotificationArchive() {
    if (this._listeNotificationArchive === undefined) {
      this.build_listesNotification();
    }
    return this._listeNotificationArchive;
  }

  get numberNewNotification() {
    if (this._listeNotificationNombreNew === undefined) {
      this.build_listesNotification();
    }
    return this._listeNotificationNombreNew;
  }

  addInternalNotification(notification: CGdsEvenementChauffeurRow): void {
    this._internalNotifications.push(notification);
    this.build_listesNotification();
  }

  removeInternalNotification(notificationType: InternalNotification): void {
    const internalNotificationLength = this._internalNotifications.length;

    this._internalNotifications = this._internalNotifications.filter((notification) => {
      return !notification.isInternalNotification(notificationType);
    });

    if (internalNotificationLength !== this._internalNotifications.length) {
      this.build_listesNotification();
    }
  }

  setLastEveId(database, lastEveId) {
    this.cm.storage.set(`eve.${database}.lastEveId`, lastEveId);
    this.lastEveId[database] = lastEveId;
  }

  addEvent() {
    /**
     * faire le addEvent dans le GDS
     */
  }

  installEventsLimo(
    database: string,
    rowsetEvents: Record<string, any>[]
  ): string {
    let txtLog = '';
    if (!rowsetEvents?.length) {
      return txtLog;
    }

    this.sortEvent(rowsetEvents);

    rowsetEvents.forEach((eve: Record<string, any>) => {
      const EVE_PARAMS = this.filterOldEvent(database, eve);
      if (!Object.keys(EVE_PARAMS).length) {
        txtLog += `\n\nEVE ancien:${eve.EVE_ID}:${eve.CEV_LIBELLE}:${eve.CPT_CLASS}:${eve.EVE_PRIMARY}`;
        return;
      }
      eve.EVE_PARAMS = EVE_PARAMS;
      txtLog        += this.processInstallEvent(database, eve, []);
    });

    this.addBuild(['build_all', 'build_listesNotification']);
    this.saveDataLocale();
    return txtLog;
  }

  /**
   * @deprecated remplacé par installEventsLimo qui utilise lastEveIdPerField
   *
   * lastEveId needed and we exclude the event after processed
   * @param database
   * @param rowsetEvents
   */
  // installEventsLimoDirect(database: string, rowsetEvents: Record<string, any>[]): string {
  //   let txtLog = '';
  //   if (!rowsetEvents?.length) {
  //     return txtLog;
  //   }
  //
  //   this.sortEvent(rowsetEvents);
  //   const eveIdToExclude: number[] = [];
  //
  //   rowsetEvents.forEach((eve) => {
  //     txtLog += this.processInstallEvent(database, eve, eveIdToExclude);
  //   });
  //
  //   this.cm.addEveIdsExclus(database, eveIdToExclude);
  //   this.addBuild(['build_all', 'build_listesNotification']);
  //   this.saveDataLocale();
  //   return txtLog;
  // }

  sortEvent(rowsetEvents: Record<string, any>[]) {
    if (rowsetEvents.length > 1) {
      rowsetEvents.sort((a, b) => a.EVE_ID - b.EVE_ID);
    }
  }

  processInstallEvent(
    database: string,
    eve: Record<string, any>,
    eveIdToExclude: number[],
  ): string {
    if (this.cm.isEveIdExclus(database, eve.EVE_ID)) {
      return '';
    }

    eve.database = database;

    try {
      const concept = eve?.CPT_CLASS?.length ? this.cm.getConcept(eve.CPT_CLASS) : null;

      if (!concept) {
        return '';
      }
  
      if (Number(eve?.EVC_FLAG_NOTIFICATION)) {
        this.setRow(
          new CGdsEvenementChauffeurRow(
            this,
            {
              EVC_ID               : eve.EVC_ID,
              EVC_CEV_ID           : eve.EVE_CEV_ID,
              EVC_DATE             : Jaf.getDate(eve.EVE_DATE),
              EVC_PARAMS           : eve.EVE_PARAMS,
              EVC_CPT_CLASS        : eve.CPT_CLASS,
              EVC_PRIMARY          : eve.EVE_PRIMARY,
              EVC_EVE_ID           : eve.EVE_ID,
              EVC_FLAG_NOTIFICATION: eve.EVC_FLAG_NOTIFICATION,
              EVC_LECTURE_DATE     : eve.EVC_LECTURE_DATE,
            },
            database,
          ),
        );
      }
  
      const row = concept.getRow(eve.EVE_PRIMARY, database);
  
      switch (+eve.EVE_CEV_ID) {
        case ClassEventId.INSERT:
          eve.EVE_PARAMS[concept.primary] = eve.EVE_PRIMARY;
          if (!row) {
            concept.setRow(new rowLoader[concept.rowClass](concept, eve.EVE_PARAMS, database));
          }
          break;
        case ClassEventId.UPDATE:
          if (row) {
            Object.keys(eve.EVE_PARAMS).forEach((j) => {
              row.setterStorage(j, eve.EVE_PARAMS[j]);
            });
          }
          break;
        case ClassEventId.DELETE:
          if (row) {
            row.localDelete();
          }
          break;
        case ClassEventId.LOG:
          if (
            eve.CPT_CLASS === 'C_Gds_Installation' &&
            eve.EVE_PARAMS?.type === this.EVENT_UPDATE_INS
          ) {
            const limos = eve.EVE_PARAMS?.limos as InfoCompte[];
            if (Array.isArray(limos)) {
              this.cm.setInfosAux(limos);
            }
            break;
          }
          break;
        default:
          break;
      }
  
      eveIdToExclude.push(eve.EVE_ID);
      return `\n\nEVE:${eve.EVE_ID}:${eve.CEV_LIBELLE}:${eve.CPT_CLASS}:${
        eve.EVE_PRIMARY
      }:${JSON.stringify(eve.EVE_PARAMS)}`;
    } catch (error) {
      console.error(error)
      return ''
    }
    
  }

  installEvents(rowsetEventsLimo, mafonction = null) {
    this.flagExecute = false;
    this.cm.onSynchro();
    if (rowsetEventsLimo && !rowsetEventsLimo.code) {
      Object.keys(rowsetEventsLimo).forEach((database) => {
        if (database === 'eval') {
          // eslint-disable-next-line no-eval
          eval(rowsetEventsLimo[database]);
        } else if (database === 'version_Wayd') {
          // console.log('version_Wayd');
        } else if (database === 'changementUrlChauffeur') {
          const chauffeur              = this.cm.getChauffeur();
          chauffeur.CHU_GDS_URL_DIVERS = rowsetEventsLimo[database].CHU_GDS_URL_DIVERS;
          chauffeur.CHU_GDS_URL_GEOLOC = rowsetEventsLimo[database].CHU_GDS_URL_GEOLOC;
          chauffeur.CHU_GDS_URL_EVC    = rowsetEventsLimo[database].CHU_GDS_URL_EVC;
          this.cm.storage.set('wayd.chauffeur', chauffeur);
          // console.log('flagDispo');
        } else {
          this.installEventsLimo(
            database,
            rowsetEventsLimo[database].rowset
          );
        }
      });
    }
    this.loadNewEventCache.encours = false;
    if (mafonction) mafonction(rowsetEventsLimo);
  }

  loadNewEvent(callback = null) {
    if (this.cm.synchroDone && !this.loadNewEventCache.encours) {
      if (this.loadNewEventCache.timeout) {
        clearTimeout(this.loadNewEventCache.timeout);
      }
      const { loadNewEventCache }    = this;
      this.loadNewEventCache.timeout = setTimeout(() => {
        loadNewEventCache.encours = false;
      }, this.tempsEntreDeux);

      const nowTime = new Date().getTime();
      if (!this.loadNewEventCache.last_time) this.loadNewEventCache.last_time = 0;
      if (this.loadNewEventCache.last_time < nowTime - this.tempsEntreDeux) {
        this.loadNewEventCache.last_time = nowTime;
        this.loadNewEventCache.encours   = true;
        const params                     = { ...this.paramsToSend };
        const paramsWait                 = { ...this.paramsToSend };
        this.paramsToSend                = {};
        params.lastEveId                 = this.lastEveId;
        this.flagExecute                 = true;
        this.cm.onSynchro();
        this.cm.gds.post('evenementChauffeur', this.actionGetEvent, params).subscribe({
          next: (rowsetEventsLimo: GetEventChauffeurResponse) => {
            Object.keys(rowsetEventsLimo).forEach((database)=>{
              if (database === 'flagDispo') return;
              this.setLastEveId(database, rowsetEventsLimo[database].lastEveId);
            });
            this.installEvents(rowsetEventsLimo, callback);
          },
          error: () => {
            this.loadNewEventCache.encours = false;
            this.flagExecute               = false;
            Object.keys(this.paramsToSend).forEach((i) => {
              if (paramsWait[i]) {
                paramsWait[i] += this.paramsToSend[i];
              }
            });
            this.paramsToSend = paramsWait;
            this.cm.failTransaction();
          },
        });
      } else if (callback) callback('nop');
    } else if (callback) callback('nop');
  }

  getInternalNotificationTemplate(
    internalNotificationType: InternalNotification,
  ): InternalNotificationTemplate | null {
    const notificationTemplate = this.internalNotificationTemplates.find(
      (template) => template.type === internalNotificationType,
    );
    return notificationTemplate || null;
  }

  private getLastEventKey(database, champ, primary) {
    return `${database  }|${  champ  }|${  primary}`;
  }

  private filterOldEvent(database: string, eve: Record<string, any>): Record<string, any> {
    const keysToKeep = Object.keys(eve.EVE_PARAMS).filter((champ: string): boolean => {
      const key              = this.getLastEventKey(database, champ, eve.EVE_PRIMARY);
      const isNewer: boolean =
        !this.lastEveIdPerField.has(key) || +eve.EVE_ID > +this.lastEveIdPerField.get(key);
      if (isNewer) {
        this.lastEveIdPerField.set(key, eve.EVE_ID);
      }
      return isNewer;
    });
    const newEveParams = {};
    keysToKeep.forEach((champ) => {
      newEveParams[champ] = eve.EVE_PARAMS[champ];
    });
    return newEveParams;
  }
}
