// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  position: fixed;
  background-color: black;
  border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-family: "Work Sans", Arial;
  padding: 3px 6px;
  font-size: 13px;
  margin-top: 8px;
  transform: translateX(-90%);
  width: max-content;
  max-width: 200px;
  animation: fade-in 300ms ease-in-out;
}
.tooltip::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  position: absolute;
  left: calc(90% - 5px);
  top: -5px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-tooltip/way-tooltip.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,wCAAA;EACA,+BAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oCAAA;AACF;AACE;EACE,WAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,mCAAA;EACA,8BAAA;EACA,kBAAA;EACA,qBAAA;EACA,SAAA;AACJ;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF","sourcesContent":[".tooltip {\n  position: fixed;\n  background-color: black;\n  border-radius: 4px;\n  color: var(--ion-color-primary-contrast);\n  font-family: \"Work Sans\", Arial;\n  padding: 3px 6px;\n  font-size: 13px;\n  margin-top: 8px;\n  transform: translateX(-90%);\n  width: max-content;\n  max-width: 200px;\n  animation: fade-in 300ms ease-in-out;\n\n  &::before {\n    content: '';\n    width: 0;\n    height: 0;\n    border-left: 5px solid transparent;\n    border-right: 5px solid transparent;\n    border-bottom: 5px solid black;\n    position: absolute;\n    left: calc(90% - 5px);\n    top: -5px;\n  }\n}\n\n@keyframes fade-in {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
