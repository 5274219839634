// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-range {
  --bar-background: linear-gradient(
      90deg,
      #ff0000 0%,
      #ff7500 21.35%,
      #ffe600 50%,
      #addc00 83.33%,
      #2ddf00 100%
    ),
    #c4c4c4;
  --bar-height: 12px;
  --bar-background-active: none;
  --knob-background: #fff;
  --knob-box-shadow: 0px 3px 6px rgba(38, 46, 56, 0.24);
  --bar-border-radius: 8px;
  --knob-size: 16px;
  --padding: 0;
}
ion-range.disabled {
  pointer-events: none;
}

ion-range::part(knob) {
  border: 1px solid var(--way-color-brand-immutable);
}

.range-container {
  position: relative;
}

.custom-tick {
  position: absolute;
  height: 12px;
  width: 1px;
  border: 0.01px solid #fff;
  top: 50%;
  left: 30.5%;
  transform: translateY(-50%);
  z-index: 2;
}
.custom-tick:nth-last-child(1) {
  left: 70%;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/price-range/price-range.component.scss"],"names":[],"mappings":"AAAA;EACE;;;;;;;;WAAA;EASA,kBAAA;EACA,6BAAA;EACA,uBAAA;EACA,qDAAA;EACA,wBAAA;EACA,iBAAA;EACA,YAAA;AACF;AACE;EACE,oBAAA;AACJ;;AAGA;EACE,kDAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,YAAA;EACA,UAAA;EACA,yBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;EACA,UAAA;AAAF;AAEE;EACE,SAAA;AAAJ","sourcesContent":["ion-range {\n  --bar-background: linear-gradient(\n      90deg,\n      #ff0000 0%,\n      #ff7500 21.35%,\n      #ffe600 50%,\n      #addc00 83.33%,\n      #2ddf00 100%\n    ),\n    #c4c4c4;\n  --bar-height: 12px;\n  --bar-background-active: none;\n  --knob-background: #fff;\n  --knob-box-shadow: 0px 3px 6px rgba(38, 46, 56, 0.24);\n  --bar-border-radius: 8px;\n  --knob-size: 16px;\n  --padding: 0;\n\n  &.disabled {\n    pointer-events: none;\n  }\n}\n\nion-range::part(knob) {\n  border: 1px solid var(--way-color-brand-immutable);\n}\n\n.range-container {\n  position: relative;\n}\n\n.custom-tick {\n  position: absolute;\n  height: 12px;\n  width: 1px;\n  border: 0.01px solid #fff;\n  top: 50%;\n  left: 30.5%;\n  transform: translateY(-50%);\n  z-index: 2;\n\n  &:nth-last-child(1) {\n    left: 70%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
