// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-popover ion-content::part(scroll) {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
ion-popover ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}

.info-icon {
  font-size: 2.25rem;
  color: var(--way-color-brand);
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/info-popover/info-popover.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,wBAAA;AAAJ;AAEI;EACE,aAAA;AAAN;;AAKA;EACE,kBAAA;EACA,6BAAA;AAFF","sourcesContent":["ion-popover {\n  ion-content::part(scroll) {\n    scrollbar-width: none;\n    -ms-overflow-style: none;\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n}\n\n.info-icon {\n  font-size: 2.25rem;\n  color: var(--way-color-brand);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
