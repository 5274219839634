import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { take, switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Chauffeur } from '@way-lib/common/interfaces/chauffeur';
import { ConnexionWaydResponse } from '@way-lib/common/interfaces/limoChauffeur';
import { LoginCredential, PhoneCredential } from '@way-lib/common/interfaces/loginCredential';
import { GeolocationService } from '@way-lib/common/services/geolocation.service';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { Gds, GdsResponse } from '@way-lib-jaf/gds';
import { Jaf } from '@way-lib-jaf/jaf';
import { SubscriptionsService } from '@way-lib/common/services/subscriptions.service';
import { CALENDAR_STORAGE_TOUR_COMPLETED_KEY,
  SENTRY_KEY_INSID,
  STORAGE_KEY_ACHID, STORAGE_KEY_GEOLOCATION_ENABLED,
  STORAGE_TOUR_COMPLETED_KEY,
} from '@way-lib/common/constant';
import { WayMode, WayModeService } from '@way-lib/common/services/way-mode.service';
import { CGdsInstallationRow } from '../jaf/rowLoader';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public chauffeur: Chauffeur = null;

  private geolocation: GeolocationService;

  constructor(
    private router: Router,
    private gds: Gds,
    private storage: Storage,
    private cm: ConceptManager,
    private wayModeService: WayModeService,
    private translate: TranslateService,
    private alertController: AlertController,
    private subscriptionsService: SubscriptionsService,
  ) {
    if (WayMode.WAY_D === this.wayModeService.getMode()) {
      this.geolocation = inject(GeolocationService);
    }
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (WayMode.WAY_PARTNER === this.wayModeService.getMode()) {
        const installation = this.cm.getInstallationPrincipal();

        if (installation?.INS_ID) {
          Sentry.setTag(SENTRY_KEY_INSID, installation?.INS_ID);
        }

        this.storage.get('wayd.chauffeur').then((chauffeur) => {
          if (chauffeur) {
            this.chauffeur = chauffeur;
            this.gds.setChauffeur(chauffeur);
            this.cm.setChauffeur(chauffeur);
            resolve(
              installation?.INS_ID &&
                installation?.INS_ETAT === CGdsInstallationRow.INS_SIGN_IN_COMPLETED,
            );
          } else {
            resolve(false);
          }
        }, reject);

        return;
      }

      this.storage.get('wayd.chauffeur').then((chauffeur) => {
        if (chauffeur) {
          this.chauffeur = chauffeur;
          this.gds.setChauffeur(chauffeur);
          this.cm.setChauffeur(chauffeur);
          resolve(true);
        } else {
          resolve(false);
        }
      }, reject);
    });
  }

  async popupAlert(error) {
    const alert = await this.alertController.create({
      header:
        error && error.length > 0
          ? error
          : `${this.translate.instant('Problème de connexion, veuillez essayer plus tard')}`,
      backdropDismiss: false,
      buttons        : [
        {
          text    : this.translate.instant('Fermer'),
          cssClass: 'secondary',
          handler : () => {},
        },
      ],
    });
    await alert.present();
  }

  loadChauffeurLanguage(langId: number = 1) {
    switch (Number(this.chauffeur?.CHU_LAN_ID) || langId) {
      case 1:
        Jaf.LAN_CODE = 'fr-FR';
        this.translate.use('fr');
        this.storage.set('wayd.LAN_CODE', 'fr');
        break;
      case 2:
        Jaf.LAN_CODE = 'en-US';
        this.translate.use('en');
        this.storage.set('wayd.LAN_CODE', 'en');
        break;
      case 4:
        Jaf.LAN_CODE = 'es-ES';
        this.translate.use('es');
        this.storage.set('wayd.LAN_CODE', 'es');
        break;
      case 6:
        Jaf.LAN_CODE = 'th-TH';
        this.translate.use('th');
        this.storage.set('wayd.LAN_CODE', 'th');
        break;
      case 7:
        Jaf.LAN_CODE = 'it-IT';
        this.translate.use('it');
        this.storage.set('wayd.LAN_CODE', 'it');
        break;
      case 10:
        Jaf.LAN_CODE = 'nl-NL';
        this.translate.use('nl');
        this.storage.set('wayd.LAN_CODE', 'nl');
        break;
      case 17:
        Jaf.LAN_CODE = 'ar-AE';
        this.translate.use('ar');
        this.storage.set('wayd.LAN_CODE', 'ar');
        break;
      case 20:
        Jaf.LAN_CODE = 'he-IL';
        this.translate.use('he');
        this.storage.set('wayd.LAN_CODE', 'he');
        break;
      default:
        Jaf.LAN_CODE = 'fr-FR';
        this.translate.use('fr');
        this.storage.set('wayd.LAN_CODE', 'fr');
        break;
    }
  }

  loginCredentialLogin(credentials: LoginCredential) {
    return new Promise((resolve, reject) => {
      this.gds
        .post('divers', `/gdsv3/connexion-wayd`, {
          login: credentials.login,
          mdp  : credentials.password,
        })
        .subscribe(
          (res: ConnexionWaydResponse) => {
            if (res.action === 'erreurLogin') {
              reject(new Error('Identifiant inconnus'));
              return;
            }
            this.chauffeur                = res.params.chauffeur;
            this.chauffeur.CHU_FLAG_DISPO = 0; // lors de la connexion on force a 0 pour forcer l'utilisateur à approuver la géolocalisation #google-user-compliance
            this.loadChauffeurLanguage();
            this.cm.setInfosAux(res.params.limos);
            this.storage.set('wayd.chauffeur', this.chauffeur).then(() => {
              if (
                res.params.chauffeur.CHU_FLAG_DISPO === 1 &&
                WayMode.WAY_D === this.wayModeService.getMode()
              ) {
                this.geolocation.requestGeolocationStart();
              }
              this.cm
                .init()
                .then(() => {
                  this.cm.onConnexion();
                  this.router.navigateByUrl('/dashboard');
                })
                .catch(console.error);
            });
            resolve(res);
          },
          (res) => {
            this.storage.clear().then(() => {
              reject(res);
              window.location.reload();
            });
          },
        );
    });
  }

  phoneCredentalLogin(credentials: PhoneCredential) {
    return new Promise((resolve, reject) => {
      this.gds
        .post('divers', `/wayp/connexion-wayp`, {
          login: credentials.phoneNumber,
          mdp  : credentials.phoneCode,
        })
        .subscribe(
          (res: ConnexionWaydResponse) => {
            if (res.action === 'erreurLogin') {
              reject(new Error('Identifiant inconnus'));
              return;
            }
            this.chauffeur = res.params.chauffeur;
            const insId    = this.chauffeur.installationPartnerId;
            this.loadChauffeurLanguage();
            this.cm.setInfosAux(res.params.limos);
            this.storage.set('wayd.chauffeur', this.chauffeur).then(() => {
              if (
                res.params.chauffeur.CHU_FLAG_DISPO === 1 &&
                WayMode.WAY_D === this.wayModeService.getMode()
              ) {
                this.geolocation.requestGeolocationStart();
              }
              this.cm
                .init()
                .then(() => {
                  this.cm.onConnexion();
                  const limoPartner      = res.params.limos?.find(
                    (limo) => limo.installation.INS_ID === insId,
                  );
                  const { installation } = limoPartner;
                  if (installation?.INS_ETAT === CGdsInstallationRow.INS_SIGN_IN_COMPLETED) {
                    this.subscriptionsService
                      .fetchSubscriptions()
                      .pipe(
                        take(1),
                        switchMap(() => this.subscriptionsService.hasActiveSubscription$),
                        map((hasActiveSubscription) => {
                          this.router.navigate(
                            hasActiveSubscription ? ['/dashboard'] : ['/parameters'],
                          );
                        }),
                      )
                      .subscribe();
                    return;
                  }

                  this.router.navigate(['/signup']);
                })
                .catch(console.error);
            });
            resolve(res);
          },
          (res) => {
            this.storage.clear().then(() => {
              reject(res);
            });
          },
        );
    });
  }

  login(credentials: LoginCredential | PhoneCredential) {
    if ('login' in credentials) {
      return this.loginCredentialLogin(credentials);
    }
    return this.phoneCredentalLogin(credentials);
  }

  requestPasswordEmail(login: string) {
    return this.gds.post('divers', `/gdsv3/chauffeur-mdp-oublier`, { login });
    /*
      .pipe(
        tap((value) => {
          if (Object.keys(value).length === 0) {
            throw new Error('bad request');
          } else {
            return value;
          }
        }),
        );
        */
  }

  async logout(reloadPage = true) {
    const calendarTourCompleted = await this.storage.get(CALENDAR_STORAGE_TOUR_COMPLETED_KEY);
    const completedAiTour       = await this.storage.get(STORAGE_TOUR_COMPLETED_KEY);

    this.chauffeur       = null;
    const achId          = await this.storage.get(STORAGE_KEY_ACHID);
    const achSecretKey   = await this.storage.get('wayd.ach_secret_key');
    const theme          = await this.storage.get('theme');
    const automaticTheme = await this.storage.get('automaticTheme');
    const geolocation    = await this.storage.get(STORAGE_KEY_GEOLOCATION_ENABLED)

    this.chauffeur = null;
    
    await this.storage.clear();

    if (this.wayModeService.getMode() === WayMode.WAY_D) {
      this.geolocation.stop();
    }
    
    await this.storage.set(STORAGE_KEY_ACHID, achId);
    await this.storage.set('wayd.ach_secret_key', achSecretKey);
    await this.storage.set('theme', theme);
    await this.storage.set('automaticTheme', automaticTheme);
    await this.storage.set(CALENDAR_STORAGE_TOUR_COMPLETED_KEY, calendarTourCompleted);
    await this.storage.set(STORAGE_TOUR_COMPLETED_KEY, completedAiTour);
    await this.storage.set(STORAGE_KEY_GEOLOCATION_ENABLED, geolocation);

    if (reloadPage) {
      window.location.reload();
    }
  }

  handleSendSMS(phoneNumber: string): Observable<GdsResponse> {
    return this.gds.post('divers', '/wayp/send-code-by-sms-for-connection', {
      phone: phoneNumber,
    });
  }

  handleVerifyCode(phoneNumber: string, code: string): Observable<GdsResponse> {
    return this.gds.post('divers', '/wayp/verify-code-for-connection', {
      phone: phoneNumber,
      code,
    });
  }
}
