// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  display: flex;
  margin-top: 1.8em;
}
:host ion-title {
  border-left: 3px solid #d0d4e6;
  border-radius: 2px;
  color: var(--color-light-gray);
  font-size: 1.3rem;
  font-weight: 500;
  padding-left: 0.8em;
  text-align: left;
  position: relative;
  padding-inline: 0.8em 0;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-sub-title/way-sub-title.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;AACF;AACE;EACE,8BAAA;EACA,kBAAA;EACA,8BAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACD,kBAAA;EACA,uBAAA;AACH","sourcesContent":[":host {\n  width: 100%;\n  display: flex;\n  margin-top: 1.8em;\n\n  ion-title {\n    border-left: 3px solid #d0d4e6;\n    border-radius: 2px;\n    color: var(--color-light-gray);\n    font-size: 1.3rem;\n    font-weight: 500;\n    padding-left: 0.8em;\n    text-align: left;\n   position: relative; \n   padding-inline: 0.8em 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
