import { Component, Input } from '@angular/core';

@Component({
  selector   : 'info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls  : ['./info-popover.component.scss'],
})
export class InfoPopoverComponent {
  @Input() message: string = '';

  @Input() iconName: string = 'information-circle';

  @Input() popoverId: string = 'info-popover';
}
