// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-family: "Josefin Sans", "Work Sans", serif;
}

.way,
.suffix {
  font-size: 1.66rem;
}

.way {
  padding: 4px 2px 0px 2px;
  border: 1px solid;
  display: inline-block;
}

.suffix {
  padding: 4px 2px 0px 2px;
  display: inline-block;
}

:host.primary .way {
  border-color: var(--way-style-txt-primary);
  color: var(--way-style-txt-primary);
}
:host.primary .suffix {
  color: var(--way-style-brand);
}

.white {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-logo/way-logo.component.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;AACF;;AAEA;;EAEE,kBAAA;AACF;;AAEA;EACE,wBAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,wBAAA;EACA,qBAAA;AACF;;AAGE;EACE,0CAAA;EACA,mCAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;;AAKA;EACE,WAAA;AAFF","sourcesContent":[":host {\n  font-family: 'Josefin Sans', 'Work Sans', serif;\n}\n\n.way,\n.suffix {\n  font-size: 1.66rem;\n}\n\n.way {\n  padding: 4px 2px 0px 2px;\n  border: 1px solid;\n  display: inline-block;\n}\n\n.suffix {\n  padding: 4px 2px 0px 2px;\n  display: inline-block;\n}\n\n:host.primary {\n  .way {\n    border-color: var(--way-style-txt-primary);\n    color: var(--way-style-txt-primary);\n  }\n\n  .suffix {\n    color: var(--way-style-brand);\n  }\n}\n\n.white {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
