import { formatDate } from '@angular/common';
import {
  CGenEtapePresenceRow,
  CGeoLieuRow,
  CGenVoitureRow,
  CGenPresenceRow,
  CGdsChampMissionRow,
  CComReglementRow,
  CComCommandeRow,
  CGenChauffeurRow,
  CComFraisMissionRow,
  CGenClientRow,
  CComTypeServiceRow,
  CComCategorieRow,
  CGdsDtrRow,
} from '@way-lib-jaf/rowLoader';
import { Jaf } from '@way-lib-jaf/jaf';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild';
import { WayBuild } from '@way-lib/common/decorator/WayBuild';
import { WayMode } from '@way-lib/common/services/way-mode.service';
import {
  MisCmiJson,
  MissionBloc,
  MissionCMI,
  MissionDayChip,
  MissionStep,
} from '../../mission/interfaces/mission';

export class CGenMissionRow extends JafRow {
  // Status SMI
  static SMI_DEVIS_EN_COURS = '1';

  static SMI_ATTENTE_TARIF = '17';

  static SMI_ATTENTE_ACCEPTATION_SOUS_TRAITANT = '15';

  static SMI_DEVIS_ENVOYE = '2';

  static SMI_ATTENTE_ATTRIBUTION = '14';

  static SMI_ENVOYER_AU_CHAUFFEUR = '16';

  static SMI_CHAUFFEUR_OK = '4';

  static SMI_MISSION_DEMARRER = '11';

  static SMI_EN_PLACE = '8';

  static SMI_MISSION_TERMINER = '9';

  static SMI_TRAITEMENT_RETOUR = '19';

  static SMI_VERIFICATION = '13';

  static SMI_FACTURABLE = '21';

  static SMI_FACTURE_GENERER = '22';

  static SMI_ANNULER = '7';

  static MIS_ETAT_COMPLETED = '1';

  static MIS_ETAT_DRAFT = '0';

  static MIS_ETAT_DELETED = '-1';

  public cptTest = 10;

  @WayChamp({
    label: '%LAB_GEN_MISSION_ID%',
    class: 'Id',
  })
  public MIS_ID;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_COM_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_Commande',
    indexed: true,
  })
  public MIS_COM_ID: CComCommandeRow;

  @WayChamp({
    label : '%LAB_GEN_MISSION_DATE_DEBUT%',
    class : 'Texte',
    builds: ['build_startTime', 'build_dayChip'],
  })
  public MIS_DATE_DEBUT;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_HEURE_DEBUT%',
    class  : 'Time',
    builds : ['build_startTime', 'build_etapes'],
    default: '',
  })
  public MIS_HEURE_DEBUT;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_SMI_ID%',
    class  : 'Liaison',
    // liaison: 'C_Gen_StatutMission',
    builds : ['build_btnStart', 'build_etapes'],
    default: '0',
  })
  public MIS_SMI_ID;

  @WayChamp({
    label : '%LAB_GEN_MISSION_HEURE_FIN%',
    class : 'Time',
    builds: ['build_etapes'],
  })
  public MIS_HEURE_FIN;

  @WayChamp({
    label: '%LAB_GEN_MISSION_HEURE_PAX%',
    class: 'Quantite',
  })
  public MIS_PAX;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_VOI_ID%',
    class  : 'Liaison',
    builds : ['build_etapes'],
    liaison: 'C_Gen_Voiture',
  })
  public MIS_VOI_ID: CGenVoitureRow;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_CHU_ID%',
    class  : 'Liaison',
    builds : ['build_etapes'],
    liaison: 'C_Gen_Chauffeur',
  })
  public MIS_CHU_ID: CGenChauffeurRow;

  @WayChamp({
    label: '%LAB_GEN_MISSION_PAR_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_Partenaire',
  })
  public MIS_PAR_ID;

  @WayChamp({
    label: '%LAB_GEN_MISSION_NOTE_CHAUFFEUR%',
    class: 'Textarea',
  })
  public MIS_NOTE_CHAUFFEUR;

  @WayChamp({
    label: '%LAB_GEN_MISSION_REF_MISSION_CLIENT%',
    class: 'Texte',
  })
  public MIS_REF_MISSION_CLIENT;

  @WayChamp({
    label: '%LAB_GEN_MISSION_PANNEAU%',
    class: 'Texte',
  })
  public MIS_PANNEAU;

  @WayChamp({
    label : '%LAB_GEN_MISSION_LISTE_PASSAGERS%',
    class : 'Textarea',
    builds: ['build_etapes'],
  })
  public MIS_LISTE_PASSAGERS;

  @WayChamp({
    label : '%LAB_GEN_MISSION_HEURE_REEL_DEBUT%',
    class : 'Time',
    builds: ['build_etapes'],
  })
  public MIS_HEURE_REEL_DEBUT;

  @WayChamp({
    label : '%LAB_GEN_MISSION_HEURE_REEL_FIN%',
    class : 'Time',
    builds: ['build_etapes'],
  })
  public MIS_HEURE_REEL_FIN;

  @WayChamp({
    label: '%LAB_GEN_MISSION_KM_DEBUT%',
    class: 'Quantite',
  })
  public MIS_KM_DEBUT;

  @WayChamp({
    label: '%LAB_GEN_MISSION_KM_FIN%',
    class: 'Quantite',
  })
  public MIS_KM_FIN;

  @WayChamp({
    label: '%LAB_GEN_MISSION_PROGRAMME%',
    class: 'Textarea',
  })
  public MIS_PROGRAMME;

  @WayChamp({
    label: '%LAB_GEN_MISSION_NOTE_INTERNE%',
    class: 'Textarea',
  })
  public MIS_NOTE_INTERNE;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_TSE_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_TypeService',
  })
  public MIS_TSE_ID;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_TVE_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_TypeVehicule',
  })
  public MIS_TVE_ID;

  @WayChamp({
    label: '%LAB_GEN_MISSION_FLAG_CONTROL%',
    class: 'Flag',
  })
  public MIS_FLAG_CONTROL;

  @WayChamp({
    label: '%LAB_GEN_MISSION_PC_NUM_TRANSPORT%',
    class: 'Textarea',
  })
  public MIS_PC_NUM_TRANSPORT;

  @WayChamp({
    label: '%LAB_GEN_MISSION_DE_NUM_TRANSPORT%',
    class: 'Texte',
  })
  public MIS_PC_HEURE_TRANSPORT;

  @WayChamp({
    label: '%LAB_GEN_MISSION_DE_NUM_TRANSPORT%',
    class: 'Textarea',
  })
  public MIS_DE_NUM_TRANSPORT;

  @WayChamp({
    label: '%LAB_GEN_MISSION_DE_HEURE_TRANSPORT%',
    class: 'Texte',
  })
  public MIS_DE_HEURE_TRANSPORT;

  @WayChamp({
    label : '%LAB_GEN_MISSION_ITINERAIRE%',
    builds: ['build_etapes'],
    class : 'Textarea',
  })
  public MIS_ITINERAIRE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_DATE_FLAG_CONTROL%',
    class: 'Datetime',
  })
  public MIS_DATE_FLAG_CONTROL;

  @WayChamp({
    label : '%LAB_GEN_MISSION_FLAG_MODIFIE%',
    class : 'Flag',
    builds: ['build_etapes', 'build_btnStart'],
  })
  public MIS_FLAG_MODIFIE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_INFO_FACTURE%',
    class: 'Textarea',
  })
  public MIS_INFO_FACTURE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_GOOGLE_KM_PREVU%',
    class: 'Quantite',
  })
  public MIS_GOOGLE_KM_PREVU;

  @WayChamp({
    label: '%LAB_GEN_MISSION_GOOGLE_HEURE_PREVU%',
    class: 'Time',
  })
  public MIS_GOOGLE_HEURE_PREVU;

  @WayChamp({
    label: '%LAB_GEN_MISSION_REPAS_QTE%',
    class: 'Quantite',
  })
  public MIS_REPAS_QTE;

  @WayChamp({
    label : '%LAB_GEN_MISSION_PJ%',
    class : 'Fichier',
    builds: ['build_attachments'],
  })
  public MIS_PJ;

  @WayChamp({
    label: '%LAB_GEN_MISSION_PEAGE%',
    class: 'Montant',
  })
  public MIS_PEAGE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_DEBOURS%',
    class: 'Montant',
  })
  public MIS_DEBOURS;

  @WayChamp({
    label: '%LAB_GEN_MISSION_PARKING%',
    class: 'Montant',
  })
  public MIS_PARKING;

  @WayChamp({
    label: '%LAB_GEN_MISSION_COMMENTAIRE_CHAUFFEUR%',
    class: 'Textarea',
  })
  public MIS_COMMENTAIRE_CHAUFFEUR;

  @WayChamp({
    label: '%LAB_GEN_MISSION_LMI_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_LegendeMission',
  })
  public MIS_LMI_ID;

  @WayChamp({
    label: '%LAB_GEN_MISSION_HEURE_INCLUS%',
    class: 'Time',
  })
  public MIS_HEURE_INCLUS;

  @WayChamp({
    label: '%LAB_GEN_MISSION_KM_INCLUS%',
    class: 'Quantite',
  })
  public MIS_KM_INCLUS;

  @WayChamp({
    label: '%LAB_GEN_MISSION_HEURE_DEPOSE%',
    class: 'Time',
  })
  public MIS_HEURE_DEPOSE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_HEURE_PRISE_EN_CHARGE%',
    class: 'Time',
  })
  public MIS_HEURE_PRISE_EN_CHARGE;

  @WayChamp({
    label : '%LAB_GEN_MISSION_TEL_PASSAGER%',
    builds: ['build_etapes'],
    class : 'Telephone',
  })
  public MIS_TEL_PASSAGER;

  @WayChamp({
    label : '%LAB_GEN_MISSION_ITINERAIRE_LABEL%',
    builds: ['build_etapes'],
    class : 'Texte',
  })
  public MIS_ITINERAIRE_LABEL;

  @WayChamp({
    label : '%LAB_GEN_MISSION_FLAG_INVISIBLE%',
    builds: ['build_etapes'],
    class : 'Flag',
  })
  public MIS_FLAG_INVISIBLE;

  @WayChamp({
    label : '%LAB_GEN_MISSION_FLAG_NOSHOW%',
    builds: ['build_etapes'],
    class : 'Flag',
  })
  public MIS_FLAG_NOSHOW;

  @WayChamp({
    label: '%LAB_GEN_MISSION_MMI_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_ModeleMission',
  })
  public MIS_MMI_ID;

  @WayChamp({
    label: '%LAB_GEN_MISSION_FLAG_DA%',
    class: 'Flag',
  })
  public MIS_FLAG_DA;

  @WayChamp({
    label : '%LAB_GEN_MISSION_BAGAGE%',
    builds: ['build_etapes'],
    class : 'Quantite',
  })
  public MIS_BAGAGE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_NUMERO%',
    class: 'Quantite',
  })
  public MIS_NUMERO;

  @WayChamp({
    label : '%LAB_GEN_MISSION_CMI%',
    builds: ['build_etapes'],
    class : 'Texte',
  })
  public MIS_CMI;

  @WayChamp({
    label : '%LAB_GEN_MISSION_VIRTUAL_PHONE_DRIVER%',
    builds: ['build_etapes'],
    class : 'Telephone',
  })
  public MIS_VIRTUAL_PHONE_DRIVER;

  @WayChamp({
    label: '%LAB_GEN_MISSION_HEURE_REEL_PAYEE%',
    class: 'Time',
  })
  public MIS_HEURE_REEL_PAYEE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_MONTANT_NET_CHAUFFEUR%',
    class: 'Montant',
  })
  public MIS_MONTANT_NET_CHAUFFEUR;

  @WayChamp({
    label: '%LAB_GEN_MISSION_MONTANT_STANDBY_CHAUFFEUR%',
    class: 'Montant',
  })
  public MIS_MONTANT_STANDBY_CHAUFFEUR;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_PICKUP_LIE_ID%',
    builds : ['build_etapes'],
    class  : 'Liaison',
    liaison: 'C_Geo_Lieu',
  })
  public MIS_PICKUP_LIE_ID;

  @WayChamp({
    label: '%LAB_GEN_MISSION_PAX_MAX%',
    class: 'Quantite',
  })
  public MIS_PAX_MAX;

  @WayChamp({
    label : '%LAB_GEN_MISSION_ETAT%',
    builds: ['build_etapes'],
    class : 'Etat',
  })
  public MIS_ETAT;

  @WayChamp({
    label: '%LAB_GEN_MISSION_CEL_PASSAGER_MODE_NORMAL%',
    class: 'Flag',
  })
  public MIS_CEL_PASSAGER_MODE_NORMAL;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_LIE_ID_PICKUP_VEHICULE%',
    class  : 'Liaison',
    liaison: 'C_Geo_Lieu',
    builds : ['build_etapes'],
  })
  public MIS_LIE_ID_PICKUP_VEHICULE: CGeoLieuRow;

  @WayChamp({
    label  : '%LAB_GEN_MISSION_LIE_ID_DEPOSE_VEHICULE%',
    class  : 'Liaison',
    liaison: 'C_Geo_Lieu',
    builds : ['build_etapes'],
  })
  public MIS_LIE_ID_DEPOSE_VEHICULE: CGeoLieuRow;

  @WayChamp({
    label: '%LAB_GEN_MISSION_VERSION%',
    class: 'Quantite',
  })
  public MIS_VERSION;

  @WayChamp({
    label: 'Url Piece jointe DO',
    class: 'Object',
  })
  public MIS_DO_LIENS_PJ;

  @WayChamp({
    label: 'Logo panneau',
    class: 'Texte',
  })
  public MIS_URL_LOGO;

  @WayChamp({
    label: '%LAB_GEN_MISSION_DECOUCHER%',
    class: 'Flag',
  })
  public MIS_DECOUCHER;

  @WayChamp({
    label: '%LAB_GEN_MISSION_TEMPS_PAUSE%',
    class: 'Time',
  })
  public MIS_TEMPS_PAUSE;

  @WayChamp({
    label: '%LAB_GEN_MISSION_TEMPS_COUPURE%',
    class: 'Time',
  })
  public MIS_TEMPS_COUPURE;

  // indique si le chauffeur n'est pas arrivé à l'etape
  public _pasArriver;

  // indique si le chauffeur n'est pas partie de l'étape
  public _pasDepart;

  // index de eprs ou en est la mission
  public _numEtape;

  // index de pers de la prochaine etape
  public _prochaineEtape;

  // le chauffeur est il a la derniere etape
  public _derniereEtape;

  public ancre = null;

  @WayChampBuild()
  public separator;

  @WayChampBuild()
  public MIS_DATE_FIN: string;

  @WayChampBuild('Index', {
    conceptName: 'C_Gen_EtapePresence',
    indexName  : 'EPR_MIS_ID',
    tri        : 'EPR_TRI',
  })
  public eprs: Array<CGenEtapePresenceRow>;

  @WayChampBuild('Array')
  public eprsNonAnnuler: Array<CGenEtapePresenceRow>;

  @WayChampBuild('Index', {
    conceptName: 'C_Gen_Presence',
    indexName  : 'PRS_MIS_ID',
    tri        : 'PRS_TRI',
  })
  public prss: Array<CGenPresenceRow>;

  @WayChampBuild('Index', {
    conceptName: 'C_Com_FraisMission',
    indexName  : 'FMI_MIS_ID',
    tri        : 'FMI_ID',
  })
  public fmis: Array<CComFraisMissionRow>;

  /**
   * timestamp de la première étape de la mission servant nottament
   * à ordorer les missions par rapport à une timeline
   */
  @WayChampBuild()
  public startTime: Date;

  /** Calendar */
  color = {
    primary  : '#12489A',
    secondary: '#fff',
  };

  /** Calendar */
  allDay: false;

  /** Calendar */
  resizable: {
    beforeStart: false;
    afterEnd: false;
  };

  /** Calendar */
  draggable: false;

  @WayChampBuild('Array')
  public modificationStartLabels;

  @WayChampBuild('Array')
  public listeNotifNonLue;

  @WayChampBuild('Array')
  public attachments: Array<string>;

  @WayChampBuild()
  public totalTtc: number;

  @WayChampBuild('Array')
  public etapes: Array<MissionStep>;

  @WayChampBuild()
  public dayChip: MissionDayChip;

  // t exte du bouton démarer de la mission
  @WayChampBuild()
  public btnStartLabel: string;

  // t exte de titre au dessus du bouton demarrer
  @WayChampBuild()
  public titreStartLabel: string;

  @WayChamp({
    label: 'LAB_GEN_MISSION_LIEN_FICHE_PDF',
    class: 'Texte',
  })
  public lienFichePdf: string;

  public hasNoteChauffeur = false;

  private flagListeNotifNonLue = true;

  private _rel: CComReglementRow;

  get rel() {
    if (!this._rel) {
      this.build_rel();
    }
    return this._rel;
  }

  /** Calendar */
  get title(): string {
    if (this.firstStep && this.lastStep) {
      const firstLabel = this.translate(this.firstStep.EPR_LIE_ID.infoLocation.title);
      const lastLabel  = this.translate(this.lastStep.EPR_LIE_ID.infoLocation.title);
      return `
        ${this.MIS_COM_ID.COM_ID}-${this.MIS_NUMERO}
        ${firstLabel} > ${lastLabel}
      `;
    }
    return this.MIS_ITINERAIRE_LABEL;
  }

  get firstStep(): CGenEtapePresenceRow {
    const { eprsNonAnnuler } = this;
    if (eprsNonAnnuler.length) {
      return eprsNonAnnuler[0];
    }
    return null;
  }

  get lastStep(): CGenEtapePresenceRow {
    const { eprsNonAnnuler } = this;
    if (eprsNonAnnuler.length > 1) {
      return eprsNonAnnuler[eprsNonAnnuler.length - 1];
    }
    return null;
  }

  get firstLieu(): CGeoLieuRow | null {
    if (this.MIS_LIE_ID_PICKUP_VEHICULE) {
      return this.MIS_LIE_ID_PICKUP_VEHICULE;
    }
    if (this.eprsNonAnnuler.length > 0) {
      return this.eprsNonAnnuler[0].EPR_LIE_ID;
    }
    return null;
  }

  get lastLieu(): CGeoLieuRow | null {
    if (this.MIS_LIE_ID_DEPOSE_VEHICULE) {
      return this.MIS_LIE_ID_DEPOSE_VEHICULE;
    }
    if (this.eprsNonAnnuler.length > 0) {
      return this.eprsNonAnnuler[this.eprsNonAnnuler.length - 1].EPR_LIE_ID;
    }
    return null;
  }

  get isAConfirmer() {
    return (
      !this.isDeleted &&
      !this.isCancelled &&
      (this.MIS_SMI_ID === CGenMissionRow.SMI_ENVOYER_AU_CHAUFFEUR || this.MIS_FLAG_MODIFIE === '1')
    );
  }

  get isConfirmer() {
    return (
      !this.isDeleted &&
      this.MIS_SMI_ID === CGenMissionRow.SMI_CHAUFFEUR_OK &&
      this.MIS_FLAG_MODIFIE === '0'
    );
  }

  get isEnCours() {
    return (
      !this.isDeleted &&
      [
        CGenMissionRow.SMI_CHAUFFEUR_OK,
        CGenMissionRow.SMI_MISSION_DEMARRER,
        CGenMissionRow.SMI_EN_PLACE,
        CGenMissionRow.SMI_MISSION_TERMINER,
      ].includes(this.MIS_SMI_ID)
    );
  }

  get isDemarrer() {
    return (
      !this.isDeleted &&
      [CGenMissionRow.SMI_MISSION_DEMARRER, CGenMissionRow.SMI_EN_PLACE].includes(this.MIS_SMI_ID)
    );
  }

  get isACloturer() {
    return !this.isDeleted && this.MIS_SMI_ID === CGenMissionRow.SMI_MISSION_TERMINER;
  }

  get isCloturer() {
    return !this.isDeleted && this.MIS_SMI_ID === CGenMissionRow.SMI_TRAITEMENT_RETOUR;
  }

  get isBillable() {
    return (
      !this.isDeleted &&
      this.MIS_COM_ID.COM_SCO_ID !== CComCommandeRow.SCO_INVOICE_PAID &&
      (this.MIS_SMI_ID === CGenMissionRow.SMI_VERIFICATION ||
        this.MIS_SMI_ID === CGenMissionRow.SMI_FACTURABLE)
    );
  }

  get isFacturer() {
    return !this.isDeleted && this.MIS_SMI_ID === CGenMissionRow.SMI_FACTURE_GENERER;
  }

  get isCancelled() {
    return !this.isDeleted && this.MIS_SMI_ID === CGenMissionRow.SMI_ANNULER;
  }

  get isDeleted(): boolean {
    return this.MIS_ETAT === CGenMissionRow.MIS_ETAT_DELETED;
  }

  get isBtnFlagNoShow() {
    return (
      this.prss.length === 0 &&
      this.MIS_FLAG_NOSHOW === '0' &&
      this.MIS_SMI_ID === CGenMissionRow.SMI_EN_PLACE
    );
  }

  private _endTime: Date;

  get endTime(): Date {
    if (!this._endTime) {
      this.build_endTime();
    }
    return this._endTime;
  }

  private _vehicleDropTime: Date;

  get vehicleDropTime(): Date {
    if (!this._vehicleDropTime) {
      this.build_vehicleDropTime();
    }
    return this._vehicleDropTime;
  }

  private _vehiclePickupTime: Date;

  get vehiclePickupTime(): Date {
    if (!this._vehiclePickupTime) {
      this.build_vehiclePickupTime();
    }
    return this._vehiclePickupTime;
  }

  private _champsMission: Array<CGdsChampMissionRow>;

  get champsMission(): Array<CGdsChampMissionRow> {
    if (!this._champsMission) {
      this.build_champsMission();
    }
    return this._champsMission;
  }

  private _CMIS: MissionCMI;

  get CMIS(): MissionCMI {
    if (!this._CMIS) {
      this.build_CMIS();
    }
    return this._CMIS;
  }

  /** Calendar */
  get start() {
    return this.startTime;
  }

  /** Calendar */
  get end() {
    return this.endTime;
  }

  build_totalTtc() {
    this.totalTtc = 0;
    this.fmis.forEach((fmi) => {
      this.totalTtc +=
        Math.round(
          fmi.FMI_VENTE_HT *
            fmi.FMI_QTE *
            (1 - fmi.FMI_POURCENTAGE_REMISE / 100) *
            (1 + fmi.FMI_TVA / 100) *
            100,
        ) / 100;
    });
  }

  @WayBuild(['MIS_PJ'])
  build_attachments() {
    const attachements = [];
    if (this.MIS_PJ) {
      const MIS_PJ = this.MIS_PJ.split(';');
      delete MIS_PJ[0];
      MIS_PJ.forEach((pj) => {
        const url = `${this.installation.INS_URL}/tools/upload-fichier/get?file=${pj}`;
        attachements.push(url);
      });
    }
    this.attachments = attachements;
  }

  build_rel() {
    const rels = this.concept.cm
      .getConcept('C_Com_Reglement')
      .getIndexSort('REL_MIS_ID', this.database, this.MIS_ID);
    if (rels && rels.length > 0) {
      [this._rel] = rels;
    } else {
      this._rel = null;
    }
  }

  @WayBuild(['MIS_CMI'])
  build_CMIS() {
    if (this._CMIS === undefined) {
      this._CMIS = {};
    }
    this._CMIS.passengers = this.getSynthese('PASSAGER');
    this._CMIS.baggage    = this.getSynthese('BAGAGE');
    this._CMIS.equipment  = this.getSynthese('EQUIPEMENT');
  }

  @WayBuild(['MIS_DATE_DEBUT', 'MIS_HEURE_DEBUT', 'MIS_HEURE_FIN'])
  build_MIS_DATE_FIN() {
    if (this.MIS_DATE_DEBUT) {
      if (this.MIS_HEURE_DEBUT && this.MIS_HEURE_FIN >= this.MIS_HEURE_DEBUT) {
        this.MIS_DATE_FIN = this.MIS_DATE_DEBUT;
      } else {
        let datePlus1     = Jaf.getDate(this.MIS_DATE_DEBUT).getDate() + 1;
        datePlus1         = Jaf.getDate(this.MIS_DATE_DEBUT).setDate(datePlus1);
        this.MIS_DATE_FIN = Jaf.date2mysql(datePlus1);
      }
    } else {
      this.MIS_DATE_FIN = null;
    }
  }

  build_eprsNonAnnuler() {
    // o n determine quelle est la prochaine étape et  l'etape en cours de la mission
    this._numEtape       = -1;
    this._prochaineEtape = 0;
    this._pasArriver     = false;
    this._pasDepart      = false;
    this._derniereEtape  = false;
    this.eprsNonAnnuler  = [];
    if (this.eprs.length === 0) {
      this._pasArriver    = this.MIS_HEURE_REEL_DEBUT === null || this.MIS_HEURE_REEL_DEBUT.length === 0;
      this._pasDepart     = this._pasArriver;
      this._derniereEtape = true;
    } else {
      let firstEpr  = null;
      let lastEpr   = null;
      let ActiveEpr = null;
      this.eprs.sort((a, b) => {
        return a.EPR_TRI * 1 - b.EPR_TRI * 1;
      });
      this.eprs.forEach((epr) => {
        if (epr.EPR_FLAG_ANNULER !== '1') {
          if (!(this._pasArriver || this._pasDepart)) {
            this._pasArriver     = epr.EPR_HEURE_ARRIVER === null || epr.EPR_HEURE_ARRIVER.length === 0;
            this._pasDepart      = epr.EPR_HEURE_DEPART === null || epr.EPR_HEURE_DEPART.length === 0;
            this._numEtape      += 1;
            this._prochaineEtape = this._numEtape;
            ActiveEpr            = epr;
          } else if (this._prochaineEtape <= this._numEtape) {
            this._prochaineEtape += 1;
          }
          if (firstEpr === null) firstEpr = epr;
          // eslint-disable-next-line no-param-reassign
          lastEpr = epr;
          this.eprsNonAnnuler.push(epr);
        }
        // eslint-disable-next-line no-param-reassign
        epr.isActive        = false;
        this._derniereEtape = this._prochaineEtape === this._numEtape;
      });

      if (ActiveEpr && this.isDemarrer && (!this._derniereEtape || this._pasDepart))
        ActiveEpr.isActive = true;

      this.eprsNonAnnuler.forEach((epr) => {
        const prssPickup = [];
        const prssDrop   = [];
        // o n détermine si des passagers montent ou descendent
        // eslint-disable-next-line no-param-reassign
        epr.isFirst = epr === firstEpr;
        // eslint-disable-next-line no-param-reassign
        epr.isLast = epr === lastEpr;
        epr.build_startTime();
        this.prss.forEach((prs) => {
          if ((prs.PRS_PC_EPR_ID === null && epr.isFirst) || prs.PRS_PC_EPR_ID === epr) {
            prssPickup.push(prs);
          }
          if ((prs.PRS_DE_EPR_ID === null && epr.isLast) || prs.PRS_DE_EPR_ID === epr) {
            prssDrop.push(prs);
          }
        });
        // eslint-disable-next-line no-param-reassign
        epr.passengerPickup = prssPickup;
        // eslint-disable-next-line no-param-reassign
        epr.passengerDrop = prssDrop;
      });
    }
  }

  @WayBuild([
    'MIS_DATE_DEBUT',
    'MIS_HEURE_DEBUT',
    'MIS_HEURE_FIN',
    'MIS_CMI',
    'MIS_TVE_ID',
    'MIS_VOI_ID',
  ])
  build_etapes() {
    /*
    if (this._etapes === undefined) {
      this._etapes = new Array<MissionStep>();
    } else {
      this._etapes.splice(0, this._etapes.length);
    }
    */
    const etapes   = new Array<MissionStep>();
    let activeDeja = false;

    this.build_eprsNonAnnuler();
    this.build_startTime();
    this.build_CMIS();
    this.build_dayChip();
    this.build_endTime();
    this.build_vehiclePickupTime();
    this.build_vehicleDropTime();

    let dernierLieu: CGeoLieuRow = null;

    this.hasNoteChauffeur = false;
    if (!this.empty('MIS_NOTE_CHAUFFEUR')) {
      this.hasNoteChauffeur = true;
    }
    this.prss.forEach((prs) => {
      if (prs.PRS_PAS_ID?.PAS_INFO_CHAUFFEUR?.length > 0) this.hasNoteChauffeur = true;
    });

    activeDeja = this.MIS_SMI_ID === CGenMissionRow.SMI_CHAUFFEUR_OK;

    etapes.push({
      startTime: this.vehiclePickupTime,
      blocs    : {
        locationStart: this.MIS_LIE_ID_PICKUP_VEHICULE
          ? this.MIS_LIE_ID_PICKUP_VEHICULE.infoLocation
          : null,
        vehiclePickup: {
          voi    : this.MIS_VOI_ID,
          CMIS   : this.CMIS,
          mission: this,
        },
      },
      viewType: {
        condensed: true,
        detail   : true,
        dashboard: true,
      },
      active: activeDeja,
    });

    if (this.MIS_LIE_ID_PICKUP_VEHICULE) {
      dernierLieu = this.MIS_LIE_ID_PICKUP_VEHICULE;
    }
    if (this.eprsNonAnnuler.length > 0) {
      this.eprsNonAnnuler.forEach((epr, index) => {
        const blocs: MissionBloc = {};
        if (index === 0 && (this.MIS_ITINERAIRE_LABEL || this.MIS_ITINERAIRE)) {
          blocs.roadSheet = {
            label : this.MIS_ITINERAIRE_LABEL,
            detail: this.MIS_ITINERAIRE,
          };
        }
        if (index === 0 && this.MIS_CEL_PASSAGER_MODE_NORMAL) {
          blocs.passengerNone = {
            mission: this,
          };
        }

        if (epr.passengerDrop.length) {
          blocs.locationArrival = epr.infoLocation;
        } else {
          blocs.locationStart = epr.infoLocation;
        }
        if (etapes.length === 0 && !this.MIS_LIE_ID_PICKUP_VEHICULE) {
          blocs.vehiclePickup = {
            voi    : this.MIS_VOI_ID,
            CMIS   : this.CMIS,
            mission: this,
          };
        }

        if (epr.passengerPickup.length) {
          blocs.passengerPickup = epr.passengerPickup;
        }
        if (epr.passengerDrop.length) {
          blocs.passengerDrop = epr.passengerDrop;
        }

        const step: MissionStep = { blocs };

        if (epr.isFirst || epr.isLast || epr.startTime) {
          step.startTime = epr.startTime;
        }

        const etape: MissionStep = step;

        // if (true || index === 0 || index === this.eprsNonAnnuler.length - 1) {
        // iannis : j'ai mis true tout le temps pour ne pas avoir à afficher le nombre d'étape pas prévue initialiament
        etape.viewType = {
          condensed: true,
        };
        // }

        if (dernierLieu !== null) {
          if (dernierLieu.LIE_TLI_ID === '4' || epr?.EPR_LIE_ID?.LIE_TLI_ID === '4') {
            etape.separator = {
              estimation: {
                distance: ``,
                time    : ``,
              },
            };
          } else if (dernierLieu) {
            const distance  = Jaf.getDistanceKm(
              dernierLieu.LIE_LAT,
              dernierLieu.LIE_LNG,
              epr.EPR_LIE_ID?.LIE_LAT,
              epr.EPR_LIE_ID?.LIE_LNG,
            );
            etape.separator = {
              estimation: {
                distance: `${Math.round(Jaf.getDistanceRouteByDistance(distance))} km`,
                time    : `${Math.round((Jaf.getTempsByDistance(distance) * 2) / 60000)} min`,
              },
            };
          }
        }
        dernierLieu = epr.EPR_LIE_ID;
        if (epr.isActive) {
          etape.active = true;
          activeDeja   = true;
        }

        etapes.push(etape);
      });
    } else {
      const blocs: MissionBloc = {
        passengerPickup: this.prss,
      };
      if (this.MIS_ITINERAIRE_LABEL || this.MIS_ITINERAIRE) {
        blocs.roadSheet = {
          label : this.MIS_ITINERAIRE_LABEL,
          detail: this.MIS_ITINERAIRE,
        };
      }
      if (!this.empty('MIS_LISTE_PASSAGERS')) {
        blocs.passengerNone = {
          mission: this,
        };
      }

      const step: MissionStep = {
        blocs,
        viewType: {
          condensed: true,
          detail   : true,
          dashboard: true,
        },
      };
      if (!activeDeja && this.MIS_SMI_ID !== CGenMissionRow.SMI_CHAUFFEUR_OK) {
        step.active = true;
        activeDeja  = true;
      }

      etapes.push(step);
    }

    if (this.eprsNonAnnuler.length < 2 && this.endTime) {
      etapes.push({
        startTime: this.endTime,
      });
    }

    if (this.MIS_LIE_ID_DEPOSE_VEHICULE) {
      const lie      = this.MIS_LIE_ID_DEPOSE_VEHICULE;
      const distance = dernierLieu
        ? Jaf.getDistanceKm(dernierLieu.LIE_LAT, dernierLieu.LIE_LNG, lie.LIE_LAT, lie.LIE_LNG)
        : 0;
      etapes.push({
        startTime: this.vehicleDropTime,
        separator: {
          estimation: {
            distance: `${Math.round(Jaf.getDistanceRouteByDistance(distance))} km`,
            time    : `${Math.round((Jaf.getTempsByDistance(distance) * 2) / 60000)} min`,
          },
        },
        blocs: {
          locationArrival: this.MIS_LIE_ID_DEPOSE_VEHICULE.infoLocation,
          vehicleDrop    : {
            voi : this.MIS_VOI_ID,
            CMIS: this.CMIS,
          },
        },
        viewType: {
          condensed: true,
          detail   : true,
          dashboard: true,
        },
        active: !activeDeja,
      });
    }

    etapes.push({
      blocs: {
        actions: [
          {
            label           : 'Détail mission',
            hasNoteChauffeur: this.hasNoteChauffeur,
            route           : ['/missions', 'detail', this.database, this.MIS_ID],
            fill            : 'outline',
          },
          {
            appBtnStart: true,
          },
        ],
      },
      viewType: {
        condensed: true,
        detail   : false,
        dashboard: false,
      },
    });

    this.etapes = etapes;
    // console.log(`je build etapes pour ${this.MIS_COM_ID.COM_ID}-${this.MIS_NUMERO}`, etapes);
    this.build_btnStart();
  }

  build_startTime() {
    if (this.MIS_DATE_DEBUT && this.MIS_HEURE_DEBUT) {
      let date       = Jaf.getDate(this.MIS_DATE_DEBUT).getTime();
      date           = (date / 1000 + Jaf.getTemps(this.MIS_HEURE_DEBUT)) * 1000;
      this.startTime = new Date(date);
    } else if (this.MIS_DATE_DEBUT) {
      const date     = Jaf.getDate(this.MIS_DATE_DEBUT).getTime();
      this.startTime = new Date(date);
    } else {
      this.startTime = null;
    }
  }

  build_endTime() {
    if (this.MIS_DATE_DEBUT && this.MIS_HEURE_FIN) {
      let date      = Jaf.getDate(this.MIS_DATE_FIN).getTime();
      date          = (date / 1000 + Jaf.getTemps(this.MIS_HEURE_FIN)) * 1000;
      this._endTime = new Date(date);
    } else {
      this._endTime = null;
    }
  }

  @WayBuild(['MIS_DATE_DEBUT', 'MIS_HEURE_DEBUT'])
  build_dayChip() {
    if (this.MIS_DATE_DEBUT) {
      let title = formatDate(this.startTime, 'fullDate', Jaf.LAN_CODE);
      if (title === formatDate(new Date(), 'fullDate', Jaf.LAN_CODE)) {
        title = "Aujourd'hui";
      }
      this.dayChip = {
        title,
        date: this.startTime,
      };
    }
  }

  build_vehiclePickupTime() {
    if (this.MIS_DATE_DEBUT && this.MIS_HEURE_REEL_DEBUT) {
      this._vehiclePickupTime = Jaf.getDate(`${this.MIS_DATE_DEBUT} ${this.MIS_HEURE_REEL_DEBUT}`);
    } else {
      this._vehiclePickupTime = null;
    }
  }

  build_vehicleDropTime() {
    if (this.MIS_DATE_DEBUT && this.MIS_HEURE_REEL_FIN) {
      this._vehicleDropTime = Jaf.getDate(`${this.MIS_DATE_DEBUT} ${this.MIS_HEURE_REEL_FIN}`);
    } else {
      this._vehicleDropTime = null;
    }
  }

  // TODO on doit pas recalculer ca à chaque row
  build_champsMission() {
    if (!this._champsMission) {
      this._champsMission = this.concept.cm.getConcept('C_Gds_ChampMission').getIndex('CMI_ID');
    }
    return this._champsMission;
  }

  changeStatus(status: string) {
    this.setDatas({ MIS_SMI_ID: status });
  }

  updateMisCmi(nomChamp, value) {
    const CMISJson: MisCmiJson = JSON.parse(this.MIS_CMI);
    CMISJson[nomChamp]         = value;
    this.setterSynchro('MIS_CMI', JSON.stringify(CMISJson));
  }

  getSynthese(CMI_GROUPE) {
    const count: {
      [key: string]: {
        label: string;
        count: number;
      };
    } = {};
    if (this.MIS_CEL_PASSAGER_MODE_NORMAL === '0') {
      if (this.MIS_CMI) {
        const CMISJson: MisCmiJson = JSON.parse(this.MIS_CMI);
        this.champsMission.forEach((row) => {
          if (row.CMI_GROUPE === CMI_GROUPE) {
            if (CMISJson[row.CMI_ID] !== undefined && CMISJson[row.CMI_ID]) {
              const nb = +CMISJson[row.CMI_ID];

              if (nb > 0) {
                if (undefined === count[row.CMI_ID]) {
                  count[row.CMI_ID] = {
                    label: `DIC_WAYD_${row.CMI_LABEL}`,
                    count: nb,
                  };
                } else {
                  count[row.CMI_ID].count += nb;
                }
              }
            }
          }
        });
      }
    } else {
      this.prss.forEach((PRS: CGenPresenceRow) => {
        let groupe;
        switch (CMI_GROUPE) {
          case 'PASSAGER':
            groupe = 'passengers';
            break;
          case 'BAGAGE':
            groupe = 'baggage';
            break;
          case 'EQUIPEMENT':
            groupe = 'equipment';
            break;
          default:
            return;
            break;
        }
        if (PRS.CMIS[groupe]) {
          const { list } = PRS.CMIS[groupe];
          list.forEach((row: { label: string; count: number }) => {
            if (undefined === count[row.label]) {
              count[row.label] = {
                label: row.label,
                count: row.count,
              };
            } else {
              count[row.label].count += row.count;
            }
          });
        }
      });
    }

    const res  = Object.values(count);
    const list = this.sortRowEquipement(res);

    let total = 0;
    list.forEach((equipement) => {
      total += equipement.count;
    });
    return { list, total };
  }

  sortRowEquipement(data) {
    data.sort((a, b) => {
      if (a.label === b.label) return 0;
      return a.label > b.label ? 1 : -1;
    });
    return data;
  }

  /* getSyntheseEquipements() {
    const listEquipement: MissionCMI = {};
    if (this.MIS_CEL_PASSAGER_MODE_NORMAL === '0') {
      const calcDetailedCMIS = this.calcDetailedCMIS(this.MIS_CMI, 'EQUIPEMENT');
      const champMissionEquipement = Object.values(calcDetailedCMIS);
      if (!champMissionEquipement.length) return null;

      const listEquipement = [];
      champMissionEquipement.forEach((cmi) => {
        listEquipement.push(this.getRowEquipement(cmi));
      });

      return this.sortRowEquipement(listEquipement);
    }

    const count: MissionCMI;
    this.prss.forEach((PRS: CGenPresenceRow) => {
      const { equipment } = PRS.CMIS;
      if (!equipment.list || !equipment.list.length) {
        return;
      }
      equipment.list.forEach((row) => {
        if (count.equipment[row.label] === undefined) {
          count[row.label] = row.count;
        } else {
          count[row.label] += row.count;
        }
      });
    });

    const res = Object.values(count);
    return this.sortRowEquipement(res);
  } */

  /*
  sortRowEquipement(data) {
    data.sort((a, b) => {
      if (a.label === b.label) return 0;
      return a.label > b.label ? 1 : -1;
    });
    return data;
  } */

  calcDetailedCMIS(CMIS, CMI_GROUPE) {
    if (!CMIS || CMIS.length <= 0) return false;

    const CMISJson: MisCmiJson = JSON.parse(CMIS);

    const champsMissionGroupe = [];
    if (CMISJson) {
      this.champsMission.forEach((row) => {
        if (row.CMI_GROUPE !== CMI_GROUPE) return;

        if (CMISJson[row.CMI_ID] !== undefined && CMISJson[row.CMI_ID]) {
          const nb = CMISJson[row.CMI_ID];

          if (!Number.isInteger(nb) || nb === 0) return;

          // eslint-disable-next-line no-param-reassign
          row.count                       = nb;
          champsMissionGroupe[row.CMI_ID] = row;
        }
      });
    }
    return champsMissionGroupe;
  }

  @WayBuild(['MIS_SMI_ID', 'MIS_FLAG_NOSHOW'])
  build_btnStart() {
    // o n determine quel est le texte du bouton
    this.btnStartLabel   = '';
    this.titreStartLabel = '';
    switch (this.MIS_SMI_ID) {
      case CGenMissionRow.SMI_ENVOYER_AU_CHAUFFEUR:
        this.btnStartLabel   = 'Confirmer';
        this.titreStartLabel = 'Pouvez vous confirmer la mission ?';
        break;
      case CGenMissionRow.SMI_CHAUFFEUR_OK:
        this.titreStartLabel = this.isConfirmedByClient
          ? 'Prise en charge du véhicule'
          : 'En attente de confirmation client';

        if (this.concept.cm.wayModeService.getMode() === WayMode.WAY_D) {
          this.btnStartLabel = 'Démarrer';
          return;
        }

        this.btnStartLabel = this.isConfirmedByClient ? 'Démarrer' : 'Client ok';

        break;
      case CGenMissionRow.SMI_MISSION_DEMARRER:
        if (this.eprsNonAnnuler.length === 0) {
          this.btnStartLabel   = 'Terminer';
          this.titreStartLabel = 'Dépose du véhicule';
        } else {
          this.btnStartLabel   = 'En place';
          this.titreStartLabel = `${'En route'}${this.getLibelleLieuProchaineEtape()}`;
        }
        break;
      case CGenMissionRow.SMI_EN_PLACE:
        if (
          this._derniereEtape === undefined ||
          this._pasDepart === undefined ||
          this._pasArriver === undefined ||
          this._numEtape === undefined
        ) {
          this.build_etapes();
        }

        if (this.MIS_FLAG_NOSHOW === '1' || (this._derniereEtape && !this._pasDepart)) {
          this.btnStartLabel   = 'Terminer';
          this.titreStartLabel = 'Dépose du véhicule';
        } else if (this._numEtape === 0) {
          this.btnStartLabel   = 'Départ';
          this.titreStartLabel = 'En attente passager';
        } else if (this._pasArriver) {
          this.btnStartLabel   = this._derniereEtape ? 'Arrivée à destination' : 'En place';
          this.titreStartLabel = `${'En route'}${this.getLibelleLieuProchaineEtape()}`;
        } else if (this._pasDepart) {
          this.btnStartLabel   = this._derniereEtape ? 'Dépose client' : 'Départ';
          this.titreStartLabel = this._derniereEtape
            ? 'En attente dépose passager'
            : 'En attente passager';
        } else {
          this.btnStartLabel   = 'En place';
          this.titreStartLabel = `${'En route'}${this.getLibelleLieuProchaineEtape()}`;
        }
        break;
      case CGenMissionRow.SMI_MISSION_TERMINER:
        this.btnStartLabel   = 'Clôturer';
        this.titreStartLabel = 'En attente de clôture';
        break;
      default:
        this.btnStartLabel   = '';
        this.titreStartLabel = '';
        break;
    }
    if (
      this.MIS_FLAG_MODIFIE === '1' &&
      [
        CGenMissionRow.SMI_CHAUFFEUR_OK,
        CGenMissionRow.SMI_MISSION_DEMARRER,
        CGenMissionRow.SMI_EN_PLACE,
      ].indexOf(this.MIS_SMI_ID) > -1
    ) {
      this.btnStartLabel = 'Confirmer';
      if (this.modificationStartLabels.length > 1) {
        this.titreStartLabel = 'Pouvez vous confirmer les modifications ?';
      } else if (
        this.modificationStartLabels.length === 0 &&
        this.MIS_SMI_ID !== CGenMissionRow.SMI_CHAUFFEUR_OK
      ) {
        this.titreStartLabel = 'Pouvez vous confirmer la mission ?';
      } else {
        this.titreStartLabel = 'Pouvez vous confirmer la modification ?';
      }
    }

    // calcul l'ancre

    this.ancre          = null;
    const decalageEtape = this.MIS_LIE_ID_PICKUP_VEHICULE ? 1 : 0;
    const num           = this._numEtape + decalageEtape;
    const prochaine     = this._prochaineEtape + decalageEtape;
    if (this.MIS_SMI_ID === CGenMissionRow.SMI_CHAUFFEUR_OK) {
      this.ancre = null;
    } else if (num < prochaine && !this._pasArriver) {
      this.ancre = `step${num}`;
    } else if (this._pasArriver) {
      this.ancre = `separator${num}`;
    } else if (this.MIS_LIE_ID_DEPOSE_VEHICULE) {
      if (this._pasDepart) {
        this.ancre = `step${num}`;
      } else {
        this.ancre = `separator${num + 1}`;
      }
    } else {
      this.ancre = `step${num}`;
    }

    /*
      console.log((this.cptTest++)+':build_btnStart['+this.MIS_COM_ID.COM_ID+'-'+this.MIS_NUMERO+']:SMI_ID='+this.MIS_SMI_ID
        + ', '+this._btnStartLabel
        +', numEtape='+this._numEtape
        +', derniereEtape='+this._derniereEtape
        +', pasArriver='+this._pasArriver
        +', pasDepart='+this._pasDepart);
  */
  }

  getLibelleLieuProchaineEtape() {
    if (this.eprs.length > 1) {
      const epr = this.eprs[this._numEtape];
      if (epr.EPR_LIE_ID.LIE_LIBELLE && epr.EPR_LIE_ID.LIE_LIBELLE.length > 0) {
        return ` ${Jaf.translate('pour')} ${epr.EPR_LIE_ID.LIE_LIBELLE}`;
      }
      return ` ${Jaf.translate('pour')} ${epr.EPR_LIE_ID.LIE_FORMATED.substr(0, 20)}`;
    }
    return '';
  }

  setFlagNoShow() {
    this.setterSynchro('MIS_FLAG_NOSHOW', '1');
    const machaine = `${
      this.MIS_COMMENTAIRE_CHAUFFEUR + this.concept.cm.translate.instant('DIC_WAYD_NO_SHOW_RAISON')
    } : ${this.concept.cm.translate.instant('DIC_WAYD_NO_SHOW_PAS_CLIENT')}`;

    this.setterSynchro('MIS_COMMENTAIRE_CHAUFFEUR', machaine);
  }

  setLectureNotif() {
    const tab     = [];
    const concept = this.concept.cm.getConcept('C_Gds_EvenementChauffeur');
    concept.all.forEach((evc) => {
      if (
        evc.EVC_PARAMS.database === this.database &&
        evc.EVC_PARAMS.misId === this.MIS_ID &&
        !evc.EVC_LECTURE_DATE
      )
        tab.push(evc);
    });
    if (tab.length > 0) concept.setLectureNotif(tab);
  }

  build_listeNotifNonLue_init() {
    if (this.flagListeNotifNonLue) {
      this.flagListeNotifNonLue = false;
      const monRow              = this;
      this.concept.cm
        .getConcept('C_Gds_EvenementChauffeur')
        .getListeNotificationMission(this.database, this.MIS_ID)
        .subscribe({
          next() {
            monRow.build_listeNotifNonLue();
          },
        });
    }
  }

  @WayBuild(['MIS_ID', 'EPR_ID', 'PRS_ID'])
  build_listeNotifNonLue() {
    this.build_listeNotifNonLue_init();
    const listeNotifNonLue = [];
    this.concept.cm
      .getConcept('C_Gds_EvenementChauffeur')
      .getListeNotificationLectureByCle(this.database, this.MIS_ID)
      .forEach((item) => {
        listeNotifNonLue.push(item);
      });
    this.listeNotifNonLue         = listeNotifNonLue;
    const modificationStartLabels = [];
    this.listeNotifNonLue.forEach((evc) => {
      if (evc.EVC_PARAMS.labelsNotif && evc.EVC_PARAMS.labelsNotif.length > 1) {
        evc.EVC_PARAMS.labelsNotif.slice(1).forEach((label) => {
          modificationStartLabels.push(label);
        });
      }
    });
    this.modificationStartLabels = modificationStartLabels;
  }

  delete() {
    this.setValue('MIS_ETAT', CGenMissionRow.MIS_ETAT_DELETED);
    super.delete();
    this.concept.launchBuildByNameField('MIS_ID');
  }

  setClient(client: CGenClientRow) {
    this.setValue(
      'MIS_COM_ID',
      this.concept.cm.getConcept('C_Com_Commande').findCommandeFor(client, this, this.database),
    );
  }

  getMainContact(): CGenPresenceRow {
    return this.prss.find((value) => {
      return value.PRS_PAS_ID.PAS_TELEPHONE === this.MIS_TEL_PASSAGER;
    });
  }

  isAirportMission(): boolean {
    const isAirport = [
      CComTypeServiceRow.TSE_AIRPORT_PICKUP,
      CComTypeServiceRow.TSE_AIRPORT_DROPOFF,
    ].includes(Number(this.MIS_TSE_ID?.TSE_ID));
    return isAirport;
  }

  includesAirportStep(): boolean {
    return this.eprs.some((epr) => epr.EPR_INFOS_VOL);
  }

  isDraft(): boolean {
    return this.MIS_ETAT === CGenMissionRow.MIS_ETAT_DRAFT;
  }

  cancel() {
    this.MIS_COM_ID.changeStatus(CComCommandeRow.SCO_CANCELLED);
    this.changeStatus(CGenMissionRow.SMI_ANNULER);
    this.updateRowsAndInterface();
  }

  onClientConfirmation() {
    if (this.MIS_SMI_ID === CGenMissionRow.SMI_DEVIS_EN_COURS) {
      this.changeStatus(CGenMissionRow.SMI_ATTENTE_ATTRIBUTION);
    }
    this.MIS_COM_ID.changeStatus(CComCommandeRow.SCO_QUOTE_CONFIRMED);
    this.updateRowsAndInterface();
  }

  setToPending() {
    if (this.MIS_SMI_ID === CGenMissionRow.SMI_ANNULER) {
      this.changeStatus(CGenMissionRow.SMI_CHAUFFEUR_OK);
    }
    this.MIS_COM_ID.changeStatus(CComCommandeRow.SCO_IN_PROGRESS);
    this.updateRowsAndInterface();
  }

  updateRowsAndInterface() {
    this.build_btnStart();
    this.concept.cm.getConcept('C_Gen_Mission').rebuildQuoteStatus();
    this.concept.cm.detectChanges();
  }

  get mainCost(): CComFraisMissionRow | null {
    return (
      this.fmis.find(
        (fmi: CComFraisMissionRow) => fmi.FMI_SER_ID.SER_ID === CComCategorieRow.CAT_MISSION,
      ) || null
    );
  }

  get totalHTPrice(): number {
    const sum = this.fmis.reduce((acc, fmi) => acc + Number(fmi.getHtPriceWithDiscount()), 0);
    return +(sum || 0).toFixed(2);
  }

  get totalTVAPrice(): number {
    const sum = this.fmis.reduce((acc, fmi) => acc + fmi.tvaPrice, 0);
    return +(sum || 0).toFixed(2);
  }

  get totalTTCPrice(): number {
    const sum = this.fmis.reduce((acc, fmi) => acc + (fmi.ttcPrice * fmi.FMI_QTE) , 0);
    return +(sum || 0).toFixed(2);
  }

  get isConfirmedByClient(): boolean {
    return this.MIS_COM_ID.COM_SCO_ID === CComCommandeRow.SCO_QUOTE_CONFIRMED;
  }

  get hasCost(): boolean {
    return this.totalTtc > 0;
  }

  get originalDtr(): CGdsDtrRow | null {
    return this.concept.cm
      .getConcept('C_Gds_Dtr')
      .all.find((dtr: CGdsDtrRow) => dtr.DTR_ST_MIS_ID === this.MIS_ID);
  }

  get isCreatedFromDtr(): boolean {
    return !!this.originalDtr;
  }

  get associatedDtr(): CGdsDtrRow | null {
    return (
      this.concept.cm
        .getConcept('C_Gds_Dtr')
        .all.find((dtr: CGdsDtrRow) => !dtr.isCancelled && dtr.DTR_DO_MIS_ID === this.MIS_ID) ||
      null
    );
  }

  get hasActiveDtr(): boolean {
    return this.associatedDtr ? !this.associatedDtr.isCancelled : false;
  }

  get whatsAppMessage(): string {
    const locationStart = this.eprs[0].EPR_LIE_ID;
    const locationEnd   = this.eprs[this.eprs.length - 1].EPR_LIE_ID;
    const vehicleType   = this.MIS_VOI_ID?.VOI_MOE_ID?.MOE_TVH_ID?.TVH_LIBELLE || '';

    return `
      ${this.startTime?.toLocaleDateString(
        Jaf.LAN_CODE,
        {},
      )} - ${this.startTime?.toLocaleTimeString(Jaf.LAN_CODE, {
      hour  : '2-digit',
      minute: '2-digit',
    })}
      ${vehicleType}
      ${locationStart?.LIE_CP} ${locationStart?.LIE_VILLE} vers ${locationEnd?.LIE_CP} ${
      locationEnd?.LIE_VILLE
    }
      ${this.totalTtc}€
      Envoyé depuis l'application Way-Partner (https://way-partner.com)
    `;
  }

  get hasStartBtn(): boolean {
    return !!this.btnStartLabel && this.btnStartLabel.length > 0;
  }

  get isAssigned(): boolean {
    return !!this.MIS_CHU_ID || this.hasActiveDtr;
  }

  get isSelfAssigned(): boolean {
    return (
      this.MIS_CHU_ID?.CHU_PAR_ID?.PAR_ID ===
      `${this.concept.cm.getInstallationPrincipal().INS_ID}00000000000`
    );
  }

  get isPending(): boolean {
    return this.MIS_COM_ID.COM_SCO_ID === CComCommandeRow.SCO_IN_PROGRESS;
  }

  get isCancelledBySender(): boolean {
    return this.isCreatedFromDtr && this.originalDtr.isCancelledByContractor;
  }

  get isFinished(): boolean {
    return [
      CGenMissionRow.SMI_MISSION_TERMINER,
      CGenMissionRow.SMI_FACTURABLE,
      CGenMissionRow.SMI_FACTURE_GENERER,
      CGenMissionRow.SMI_ANNULER,
    ].includes(this.MIS_SMI_ID);
  }

  get typeService(): number {
    if (!this.MIS_TSE_ID) return CComTypeServiceRow.TSE_OTHER;

    return typeof this?.MIS_TSE_ID === 'string' ? this?.MIS_TSE_ID : this.MIS_TSE_ID?.TSE_ID;
  }
}
