import { CGdsInstallationRow } from '@way-lib-jaf/rowLoader';
import { Jaf } from '@way-lib-jaf/jaf';
import { JafConcept } from '@way-lib-jaf/concept';
import { TENANT_ID_NUMBER } from "../../constant";

export abstract class JafRow {
  public database;

  private _dataModified = [];

  private _modeInsert = true;

  public _installation;

  public listeChamps;

  public listeBuild;

  public listeChampMakeBuild;

  public launchBuildByRowList = [];

  constructor(public concept: JafConcept, data, database?) {
    this.database = database || concept.cm.getDatabase();
    const champs  = this.getChamps();
    this.initBuild();
    Object.keys(champs).forEach((nc) => {
      if (champs[nc].default) {
        this[nc] = champs[nc].default;
      } else {
        this[nc] = null;
      }
    });

    if (data[concept.primary]) {
      this._modeInsert = false;
      Object.keys(data).forEach((nc) => {
        this.setterStorage(nc, data[nc]);
      });
    } else {
      this[concept.primary] = `${this.getNewIncrement()}`;
      this._dataModified.push(concept.primary);
      Object.keys(data).forEach((nc) => {
        this.setter(nc, data[nc]);
        this._dataModified.push(nc);
      });
    }

    this.concept.launchBuildByNameField(concept.primary);
    concept.cm.launchBuildByNameField(concept.primary);
  }

  getNewIncrement() {
    if (this.concept._flagUniqueRowset) {
      return Math.floor(Math.random() * 1000000000 + 1000000000);
    }
    const installation = this.concept.cm.getInstallation(this.database);
    if (installation) {
      const temps = new Date().getTime() - new Date('2021-01-01 00:00:00').getTime();
      let id      = Math.floor(temps / 10) + installation.LCH_INS_ID * TENANT_ID_NUMBER;
      if (id <= this.concept.lastId) {
        id = this.concept.lastId + 1;
      }
      this.concept.lastId = id;
      return id;
    }
    return Math.floor(Math.random() * 1000000 + 9900000000);
  }

  setChamp(name, params) {
    if (this.listeChamps === undefined) {
      this.listeChamps = {};
    }
    this.listeChamps[name] = params;
    // console.log(`setChamp:${name}=${JSON.stringify(params)}`);
  }

  translate(nc) {
    return Jaf.translate(this[nc]);
  }

  getChamps() {
    return this.listeChamps;
  }

  initBuild() {
    if (this.listeBuild !== undefined) {
      this.listeBuild.forEach(([key, valueType, options]) => {
        Object.defineProperty(this, `_${key}`, {
          writable  : true,
          enumerable: true,
        });
        switch (valueType) {
          case 'Array':
            Object.defineProperty(this, key, {
              get: () => {
                if (this[`_${key}`] === undefined) {
                  if (this[`build_${key}`]) {
                    this[`build_${key}`]();
                  } else {
                    this[`_${key}`] = [];
                  }
                }
                // console.log(`wayChampBuild sur ${key}`, this.getIdentity(), this[`_${key}`]);
                return this[`_${key}`];
              },
              set: (v) => {
                this[`_${key}`] = v;
              },
            });
            break;
          case 'Index':
            if (!this[`build_${key}`]) {
              this[`build_${key}`] = () => {
                this[`_${key}`] = this.concept.cm
                  .getConcept(options.conceptName)
                  .getIndexSort(
                    options.indexName,
                    this.database,
                    this[this.concept.primary],
                    options.tri,
                  );
              };
            }
          // Attention ne pas mettre de break, on a besoin d'avoir le defineProperty de default
          // eslint-disable-next-line no-fallthrough
          default:
            Object.defineProperty(this, key, {
              get: () => {
                if (this[`_${key}`] === undefined) {
                  this[`_${key}`] = null;
                  if (this[`build_${key}`]) {
                    this[`build_${key}`]();
                  }
                }
                // console.log(`wayChampBuild sur ${key}`, this.getIdentity());
                return this[`_${key}`];
              },
              set: (v) => {
                // eslint-disable-next-line no-param-reassign
                this[`_${key}`] = v;
              },
            });
            break;
        }
      });
    }
  }

  doIndex() {
    this.concept.doIndex(this);
  }

  launchBuildByRow(liste) {
    liste.forEach((v) => {
      if (!this.launchBuildByRowList.includes(v)) {
        this.launchBuildByRowList.push(v);
      }
    });
    this.concept.cm.launchBuildByRow(this);
  }

  executeBuilds() {
    const liste = this.launchBuildByRowList.slice();
    // c onsole.log('executeBuilds:Liste=',liste,this);
    this.launchBuildByRowList = [];
    liste.forEach((method) => {
      if (this[method]) {
        try {
          this[method]();
        } catch (e) {
          console.error(`Erreur lors du build sur ${this.concept.getRowClass()}.${method}`, e);
        }
      } else {
        console.error('Méthode introuvable: ', { method, concept: this.concept.getClass() });
      }
    });
  }

  getterLiaisonId(name) {
    if (this[name] === null) {
      return null;
    }
    if (typeof this[name] === 'object') {
      return this[name].getIdentity();
    }
    return this[name];
  }

  videDataModified() {
    this._dataModified = [];
  }

  save(callback?) {
    if(callback){
      this.concept.cm.postSaveCallbacks.push(callback)
    }
    if (this._dataModified.length > 0) {
      const data                 = {};
      data[this.concept.primary] = this.getIdentity();
      this._dataModified.forEach((name) => {
        const champ = this.listeChamps[name];
        if (champ) {
          let valeurTmp = this[name];
          this.setterStorage(name, valeurTmp);
          if (champ.liaison) {
            valeurTmp = this.getterLiaisonId(name);
          } else if (champ.class === 'Date') {
            valeurTmp = Jaf.date2mysql(valeurTmp);
          } else if (champ.class === 'Datetime') {
            valeurTmp = Jaf.date2mysqltime(valeurTmp);
          } else if (champ.class === 'Object') {
            valeurTmp = JSON.stringify(valeurTmp);
          }

          data[name] = valeurTmp;
        }
      });
      this.concept.cm.addSynchro(
        this.database ? this.database : this.concept.cm.getDatabase(),
        this._modeInsert ? 'insert' : 'update',
        this.concept.getClass(),
        data,
      );
      this.videDataModified();
      this._modeInsert = false;
      this.concept.setRow(this);
    }
  }

  setDatas(obj: Object, save:boolean = true, callback?) {
    Object.keys(obj).forEach((name) => {
      if (this.listeChamps[name]) {
        this.setValue(name, obj[name]);
      }
    });
    if (save) {
      this.save(callback);
    }
  }

  setValue(name: string, valeur) {
    if (valeur !== this[name]) {
      this.setterStorage(name, valeur);
      if (!this._dataModified.includes(name)) {
        this._dataModified.push(name);
      }
    }

    return this;
  }

  setValueTemporary(name: string, valeur) {
    if (valeur !== this[name]) {
      this.setter(name, valeur);
      if (!this._dataModified.includes(name)) {
        this._dataModified.push(name);
      }
    }

    return this;
  }

  setterSynchro(name: string, valeur, callback?:() => void) {
    if (valeur !== this[name]) {
      this.setValue(name, valeur);
      this.save(callback);
    }
    return this;
  }

  setterStorage(name: string, valeur) {
    if (valeur !== this[name]) {
      this.setter(name, valeur);
      this.concept.askToSaveDataLocale();
    }
    return this;
  }

  setter(name, value) {
    const champ = this.listeChamps[name];

    if (!champ) {
      return this;
    }

    if (champ.class === 'Date' || champ.class === 'Datetime') {
      this.setterDate(name, value);
    } else if (champ.class === 'Object') {
      this[name] = Jaf.parse(value);
    } else if (champ.liaison) {
      this.setterLiaison(name, value);
    } else if (this[name] !== value) {
      this[name] = value;
    } else {
      return this;
    }

    if (champ.builds) this.launchBuildByRow(champ.builds);
    if (this.listeChampMakeBuild) {
      if (this.listeChampMakeBuild[name]) {
        this.launchBuildByRow(this.listeChampMakeBuild[name]);
      } else if (this.listeChampMakeBuild[this.concept.primary]) {
        this.launchBuildByRow(this.listeChampMakeBuild[this.concept.primary]);
      }
    }
    this.concept.cm.launchBuildByNameField(name);
    if (this.concept.builds.length > 0) {
      this.launchBuildByRow(this.concept.builds);
    }
    return this;
  }

  setterDate(name, value) {
    const date = Jaf.getDate(value);
    this[name] = date;
    return this;
  }

  setterLiaison(name, value) {
    if (value === null || value === '0') {
      this[name] = null;
      return this;
    }

    if (typeof value === 'object') {
      if (this[name] !== value) {
        this[name] = value;
      }
    } else {
      const champ          = this.listeChamps[name];
      const conceptLiaison = this.concept.cm.getConcept(champ.liaison);
      if (!conceptLiaison) {
        this[name] = value;
        return this;
      }

      const row = conceptLiaison.getRow(value, this.database);
      if (!row) {
        this[name] = value;
        this.addLiaisonAttente(name, conceptLiaison, 0);
        return this;
      }
      this.setterLiaison(name, row);
    }
    return this;
  }

  addLiaisonAttente(name, conceptLiaison, nb) {
    setTimeout(() => {
      const row = conceptLiaison.getRow(this[name], this.database);
      if (!row) {
        if (nb < 10) {
          this.addLiaisonAttente(name, conceptLiaison, nb + 1);
        }
      } else {
        this[name] = row;
        this.concept.cm.detectChanges();
      }
    }, 200);
  }

  empty(name) {
    const value = this[name];
    if (value === undefined) return true;
    if (value === null) return true;
    if (typeof value === 'number') return false;
    if (typeof value === 'string') return value.length === 0;
    return false;
  }

  toJSON() {
    const data   = {};
    const champs = this.getChamps();
    Object.keys(champs).forEach((name) => {
      const champ = champs[name];
      if (champ.liaison) {
        const id = this.getterLiaisonId(name);
        if (id === null) {
          data[name] = 0;
        } else {
          data[name] = id;
        }
      } else if ((!champ.default && this[name] !== null) || this[name] !== champ.default) {
        data[name] = this[name];
      }
    });
    return data;
  }

  getIdentity() {
    return this[this.concept.primary];
  }

  get installation(): CGdsInstallationRow {
    if (!this._installation) {
      this._installation = this.concept.cm.getInstallation(this.database).installation;
    }
    return this._installation;
  }

  localDelete() {
    this.concept.deleteRow(this);
    const champs = this.getChamps();
    Object.keys(champs).forEach((nc) => {
      const champ = champs[nc];
      if (champ.builds) {
        this.launchBuildByRow(champ.builds);
      }
      if (this.listeChampMakeBuild && this.listeChampMakeBuild[nc]) {
        this.launchBuildByRow(this.listeChampMakeBuild[nc]);
      }

      this.concept.cm.launchBuildByNameField(champ.name);
    });
  }

  delete() {
    this.localDelete();
    this.concept.cm.addSynchro(this.database, 'delete', this.concept.getClass(), this.toJSON());
  }
}
