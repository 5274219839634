import { CGenCiviliteRow , CGenVoitureRow , CGenCatChaRow , CGenCategorieChauffeurRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild'
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenChauffeurRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_ID%',
    class: 'Id',
  })
  public CHU_ID;

  @WayChamp({
    label  : '%LAB_GEN_CHAUFFEUR_PAR_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Partenaire',
    indexed: true,
  })
  public CHU_PAR_ID;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_MATRICULE%',
    class: 'Texte',
  })
  public CHU_MATRICULE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_EMAIL%',
    class: 'Email',
  })
  public CHU_EMAIL;

  @WayChamp({
    label  : '%LAB_GEN_CHAUFFEUR_CIV_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Civilite',
  })
  public CHU_CIV_ID: CGenCiviliteRow;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NOM%',
    class: 'Texte',
  })
  public CHU_NOM;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_PRENOM%',
    class: 'Texte',
  })
  public CHU_PRENOM;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_ADRESSE%',
    class: 'Texte',
  })
  public CHU_ADRESSE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_CODE_POSTAL%',
    class: 'Codepostal',
  })
  public CHU_CODE_POSTAL;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_VILLE%',
    class: 'Ville',
  })
  public CHU_VILLE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_TEL_FIXE%',
    class: 'Telephone',
  })
  public CHU_TEL_FIXE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_TEL_MOBILE_1%',
    class: 'Telephone',
  })
  public CHU_TEL_MOBILE_1;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_TEL_MOBILE_2%',
    class: 'Telephone',
  })
  public CHU_TEL_MOBILE_2;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_NAISSANCE%',
    class: 'Date',
  })
  public CHU_DATE_NAISSANCE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_CODE_POSTAL_NAISSANCE%',
    class: 'Codepostal',
  })
  public CHU_CODE_POSTAL_NAISSANCE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_VILLE_NAISSANCE%',
    class: 'Ville',
  })
  public CHU_VILLE_NAISSANCE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_PAY_NAISSANCE%',
    class: 'Texte',
  })
  public CHU_PAY_NAISSANCE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NATIONALITE%',
    class: 'Texte',
  })
  public CHU_NATIONALITE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_PHOTO%',
    class: 'Image',
  })
  public CHU_PHOTO;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_LAN_ID%',
    class: 'Liaison',
    // liaison: 'C_Cms_Langue',
  })
  public CHU_LAN_ID;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_SECU%',
    class: 'Texte',
  })
  public CHU_NUMERO_SECU;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_CARTE_VERTE%',
    class: 'Texte',
  })
  public CHU_NUMERO_CARTE_VERTE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VALIDITE_CARTE_VERTE%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_CARTE_VERTE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VISITE_MEDICALE%',
    class: 'Date',
  })
  public CHU_DATE_VISITE_MEDICALE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VALIDITE_VISITE_MEDICALE%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_VISITE_MEDICALE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_CARTE_SEJOUR%',
    class: 'Texte',
  })
  public CHU_CARTE_SEJOUR;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_CARTE_SEJOUR%',
    class: 'Date',
  })
  public CHU_DATE_CARTE_SEJOUR;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_PERMIS%',
    class: 'Texte',
  })
  public CHU_NUMERO_PERMIS;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_PERMIS%',
    class: 'Date',
  })
  public CHU_DATE_PERMIS;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_CARTE_ID%',
    class: 'Texte',
  })
  public CHU_NUMERO_CARTE_ID;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_CARTE_ID%',
    class: 'Date',
  })
  public CHU_DATE_CARTE_ID;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_CARTE_PRO%',
    class: 'Texte',
  })
  public CHU_NUMERO_CARTE_PRO;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_CARTE_PRO%',
    class: 'Date',
  })
  public CHU_DATE_CARTE_PRO;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_CARTE_QUALIFICATION_CONDUCTEUR%',
    class: 'Texte',
  })
  public CHU_CARTE_QUALIFICATION_CONDUCTEUR;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VALIDITE_CARTE_QUALIFICAT%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_CARTE_QUALIFICAT;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_CHRONOTACHYGRAPHE%',
    class: 'Texte',
  })
  public CHU_NUMERO_CHRONO;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_CHRONOTACHYGRAPHE%',
    class: 'Texte',
  })
  public CHU_NUMERO_CHRONOTACHYGRAPHE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VALIDITE_CHRONOTACHYGRAPH%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_CHRONO;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VALIDITE_CHRONOTACHYGRAPH%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_CHRONOTACHYGRAPH;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NOM_FORMATION_SECOURITE%',
    class: 'Texte',
  })
  public CHU_NOM_FORMATION_SECOURITE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VALIDITE_FORMATION_SECOUR%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_FORMATION_SECOUR;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_NUMERO_CARTE_CARBURANT%',
    class: 'Texte',
  })
  public CHU_NUMERO_CARTE_CARBURANT;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_VALIDITE_CARTE_CARBURANT%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_CARTE_CARBURANT;

  @WayChamp({
    label: '%LAB_GEN_VOITURE_DATE_VALIDITE_CB_PRO%',
    class: 'Date',
  })
  public CHU_DATE_VALIDITE_CB_PRO;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_FLAG_INTERNE%',
    class: 'Flag',
  })
  public CHU_FLAG_INTERNE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_TCE_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_TypeContratEmbauche',
  })
  public CHU_TCE_ID;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_COEFFICIENT%',
    class: 'Texte',
  })
  public CHU_COEFFICIENT;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_DEBUT_CONTRAT%',
    class: 'Date',
  })
  public CHU_DATE_DEBUT_CONTRAT;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_FIN_CONTRAT%',
    class: 'Date',
  })
  public CHU_DATE_FIN_CONTRAT;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_EMBAUCHE%',
    class: 'Date',
  })
  public CHU_DATE_EMBAUCHE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_DATE_CREATION%',
    class: 'Date',
  })
  public CHU_DATE_CREATION;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_INFOS%',
    class: 'Textarea',
  })
  public CHU_INFOS;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_PJ%',
    class: 'Fichier',
  })
  public CHU_PJ;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_GOOGLE_TOKEN%',
    class: 'Texte',
  })
  public CHU_GOOGLE_TOKEN;

  @WayChamp({
    label  : '%LAB_VOITURE%',
    class  : 'Liaison',
    liaison: 'C_Gen_Voiture',
  })
  public CHU_VOI_ID: CGenVoitureRow;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_FLAG_PLANNING%',
    class: 'Flag',
  })
  public CHU_FLAG_PLANNING;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_FLAG_BTN_HEURE_KM%',
    class: 'Flag',
  })
  public CHU_FLAG_BTN_HEURE_KM;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_LANGUE_PARLE%',
    class: 'Texte',
  })
  public CHU_LANGUE_PARLE;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_ETAT%',
    class: 'Etat',
  })
  public CHU_ETAT;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_TRI%',
    class: 'Tri',
  })
  public CHU_TRI;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_REFERENCE_WAYNIUM%',
    class: 'Quantite',
  })
  public CHU_REFERENCE_WAYNIUM;

  @WayChamp({
    label: '%LAB_GEN_CHAUFFEUR_FLAG_SMS_POINTAGE%',
    class: 'Flag',
  })
  public CHU_FLAG_SMS_POINTAGE;

  @WayChampBuild('Index', {
    conceptName: 'C_Gen_CatCha',
    indexName  : 'CCA_CHU_ID',
    tri        : 'CCA_ID',
  })
  public driverCategories: CGenCatChaRow[];

  get languages(): CGenCatChaRow[] {
    return this.driverCategories.filter((category:CGenCatChaRow) => category.CCA_CCH_ID?.CCH_CCH_ID?.CCH_ID === CGenCategorieChauffeurRow.CCH_LANGUAGES)
  }

  get initials(): string {
    const firstName = this.CHU_PRENOM || '';
    const lastName  = this.CHU_NOM || '';

    const getNameInitial = (name: string): string => {
      return `${name ? name[0].toUpperCase() : ''}`;
    };

    return `${getNameInitial(firstName)}${getNameInitial(lastName)}`;
  }

}
