// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabler {
  display: flex;
  stroke-width: 1px;
}

.size-xl {
  width: 64px;
  height: 64px;
}

.size-lg {
  width: 29px;
  height: 29px;
}

.size-l {
  width: 26px;
  height: 26px;
}

.size-md {
  width: 22px;
  height: 22px;
}

.size-sm {
  width: 19px;
  height: 19px;
}

.size-xs {
  width: 18px;
  height: 18px;
}

.size-xxs {
  width: 16px;
  height: 16px;
}

.white {
  color: #fff;
}

.success {
  color: var(--way-color-success);
}

.danger {
  color: var(--way-color-danger);
}

.warning {
  color: var(--way-color-warning-alt-l1);
}

.info {
  color: var(--way-color-pending);
}

.brand {
  color: var(--way-color-brand);
}

.l1 {
  color: var(--way-color-l1);
}

.m1 {
  color: var(--way-color-m1);
}

.m2 {
  color: var(--way-color-m2);
}

.m3 {
  color: var(--way-color-m3);
}

.d1 {
  color: var(--way-color-d1);
}

.d2 {
  color: var(--way-color-d2);
}

.n2 {
  color: var(--way-color-n2);
}

.brand-l2 {
  color: var(--way-color-brand-l2);
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/tabler-icon/tabler-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,sCAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,gCAAA;AACF","sourcesContent":[".tabler {\n  display: flex;\n  stroke-width: 1px;\n}\n\n.size-xl {\n  width: 64px;\n  height: 64px;\n}\n\n.size-lg {\n  width: 29px;\n  height: 29px;\n}\n\n.size-l {\n  width: 26px;\n  height: 26px;\n}\n\n.size-md {\n  width: 22px;\n  height: 22px;\n}\n\n.size-sm {\n  width: 19px;\n  height: 19px;\n}\n\n.size-xs {\n  width: 18px;\n  height: 18px;\n}\n\n.size-xxs {\n  width: 16px;\n  height: 16px;\n}\n\n.white {\n  color: #fff;\n}\n\n.success {\n  color: var(--way-color-success);\n}\n\n.danger {\n  color: var(--way-color-danger);\n}\n\n.warning {\n  color: var(--way-color-warning-alt-l1);\n}\n\n.info {\n  color: var(--way-color-pending);\n}\n\n.brand {\n  color: var(--way-color-brand);\n}\n\n.l1 {\n  color: var(--way-color-l1);\n}\n\n.m1 {\n  color: var(--way-color-m1);\n}\n\n.m2 {\n  color: var(--way-color-m2);\n}\n\n.m3 {\n  color: var(--way-color-m3);\n}\n\n.d1 {\n  color: var(--way-color-d1);\n}\n\n.d2 {\n  color: var(--way-color-d2);\n}\n\n.n2 {\n  color: var(--way-color-n2);\n}\n\n.brand-l2 {\n  color: var(--way-color-brand-l2);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
