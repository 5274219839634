// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  box-shadow: var(--way-style-box-shadow);
  width: 100%;
}
img.secondary {
  display: block;
  width: 22px;
  box-shadow: none;
  filter: brightness(1);
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/icons/flag/flag.component.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,WAAA;AACF;AACE;EACE,cAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;AACJ","sourcesContent":["img {\n  box-shadow: var(--way-style-box-shadow);\n  width: 100%;\n\n  &.secondary {\n    display: block;\n    width: 22px;\n    box-shadow: none;\n    filter: brightness(1);\n    border-radius: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
