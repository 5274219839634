import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@way-lib/common/services/auth/auth.service';
import { ConceptManager } from '@way-lib-jaf/concept-manager';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  main = [
    [
      {
        title: 'Home',
        url  : ['/dashboard'],
        icon : 'ri-home-line',
      },
    ]
  ];

  constructor(
    private authService: AuthService,
    private cm: ConceptManager,
    private translate: TranslateService,
  ) {}
}
