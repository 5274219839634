import { Component, Input } from '@angular/core';
import { GeolocationService } from '@way-lib/common/services/geolocation.service';

@Component({
  selector   : 'geolocation-button',
  templateUrl: './geolocation-button.component.html',
  styleUrls  : ['./geolocation-button.component.scss'],
})
export class GeolocationButtonComponent {
  @Input() color = 'white';

  @Input() size = 'l';

  constructor(public geolocationService: GeolocationService) {}
}
