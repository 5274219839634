import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from './components/way-tooltip/way-tooltip.module';

import { WayLogoComponent } from './components/way-logo/way-logo.component';
import { WayInputMobileComponent } from './components/way-input-mobile/way-input-mobile.component';
import { WaySegmentScrollComponent } from './components/way-segment-scroll/way-segment-scroll.component';
import { WayUnderlineComponent } from './components/way-underline/way-underline.component';
import { WayCodeIdComponent } from './components/way-code-id/way-code-id.component';
import { WayTabsComponent } from './components/way-tabs/way-tabs.component';
import { WayInputComponent } from './components/way-input/way-input.component';
import { WayBreadcrumbComponent } from './components/way-breadcrumb/way-breadcrumb.component';
import { WayNavigationComponent } from './components/way-navigation/way-navigation.component';
import { WaySegmentNavComponent } from './components/way-segment-nav/way-segment-nav.component';
import { WaySubTitleComponent } from './components/way-sub-title/way-sub-title.component';
import { WaySearchComponent } from './components/way-search/way-search.component';
import { WayClickablePhoneNumberComponent } from './components/way-clickable-phone-number/way-clickable-phone-number.component';
import { WayNumberInputComponent } from './components/way-number-input/way-number-input.component';
import { WayHeaderComponent } from './components/way-header/way-header.component';
import { WayItemComponent } from './components/way-item/way-item.component';
import { WayListComponent } from './components/way-list/way-list.component';
import { WayMainTitleComponent } from './components/way-main-title/way-main-title.component';
import { WayInputCodeComponent } from './components/way-input-code/way-input-code.component';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { RemixIconComponent } from './components/icons/remix-icon/remix-icon.component';
import { FlagComponent } from './components/icons/flag/flag.component';
import { SubtitleComponent } from './subtitle/subtitle.component';
import { WayMenuComponent } from './components/way-menu/way-menu.component';
import { WayErrorComponent } from './components/way-error/way-error.component';
import { FlagCheckeredComponent } from './components/icons/font-awesome/flag-checkered';
import { ArrowMoveLeftComponent } from './components/icons/arrow-move-left/arrow-move-left.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CopyComponent } from './components/copy/copy.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { TablerIconComponent } from './components/tabler-icon/tabler-icon.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { PriceRangeComponent } from './components/price-range/price-range.component';
import { InfoPopoverComponent } from './components/info-popover/info-popover.component';
import { GeolocationButtonComponent } from './components/geolocation-button/geolocation-button.component'

@NgModule({
  declarations: [
    WayLogoComponent,
    WayTabsComponent,
    WaySegmentScrollComponent,
    WayUnderlineComponent,
    WayInputMobileComponent,
    WayInputCodeComponent,
    WayInputComponent,
    WayCodeIdComponent,
    WayBreadcrumbComponent,
    WayMenuComponent,
    WayNavigationComponent,
    WaySegmentNavComponent,
    WayMainTitleComponent,
    WaySubTitleComponent,
    WaySearchComponent,
    WayListComponent,
    WayItemComponent,
    WayClickablePhoneNumberComponent,
    WayNumberInputComponent,
    WayHeaderComponent,
    WayErrorComponent,
    RemixIconComponent,
    FlagComponent,
    SubtitleComponent,
    FlagCheckeredComponent,
    LoaderComponent,
    ArrowMoveLeftComponent,
    CopyComponent,
    NavTabsComponent,
    TablerIconComponent,
    VehicleComponent,
    PriceRangeComponent,
    InfoPopoverComponent,
    GeolocationButtonComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    CommonModule,
    TranslateModule,
    DirectivesModule,
    PipesModule,
    TooltipModule,
  ],
  exports: [
    DirectivesModule,
    PipesModule,
    WayLogoComponent,
    WayTabsComponent,
    WaySegmentScrollComponent,
    WayUnderlineComponent,
    WayInputMobileComponent,
    WayInputCodeComponent,
    WayInputComponent,
    WayCodeIdComponent,
    WayBreadcrumbComponent,
    WayMenuComponent,
    WayNavigationComponent,
    WaySegmentNavComponent,
    WayMainTitleComponent,
    WaySubTitleComponent,
    WaySearchComponent,
    WayListComponent,
    WayItemComponent,
    WayClickablePhoneNumberComponent,
    WayNumberInputComponent,
    WayHeaderComponent,
    WayErrorComponent,
    RemixIconComponent,
    FlagComponent,
    SubtitleComponent,
    FlagCheckeredComponent,
    TooltipModule,
    LoaderComponent,
    CopyComponent,
    ArrowMoveLeftComponent,
    TranslateModule,
    NavTabsComponent,
    TablerIconComponent,
    VehicleComponent,
    PriceRangeComponent,
    InfoPopoverComponent,
    GeolocationButtonComponent
  ],
})
export class WayCommonModule {}
