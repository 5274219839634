import { CGenVoitureRow, CGenPresenceRow, CGenMissionRow } from '@way-lib-jaf/rowLoader';
import { InfoVol } from '@way-lib/common/interfaces/flystat';

export interface MissionBlockActionsData {
  label: string;
  hasNoteChauffeur?: boolean;
  route: Array<string>;
  fill?: 'outline';
}

export interface MissionProgressionButton {
  appBtnStart: boolean;
}

export interface MissionDayChip {
  title?: string; // label de la date ex: Aujourd'hui
  date: Date; // en format date typescript
}

export interface MissionRoadSheet {
  label: string;
  detail: string;
}

export interface MissionLocation {
  title: string;
  formated?: string;
  subtitle?: string;
  zipCity?: string;
  addressCommentary?: string;
  stepCommentary?: string;
  zone?: string;
  infoVol?: InfoVol;
}

export interface MissionVehicule {
  voi: CGenVoitureRow;
  mission: CGenMissionRow;
  CMIS: MissionCMI;
}

export interface MissionBloc {
  actions?: Array<MissionBlockActionsData | MissionProgressionButton>;
  locationStart?: MissionLocation;
  locationArrival?: MissionLocation;
  vehiclePickup?: {
    voi: CGenVoitureRow;
    CMIS: MissionCMI;
    mission: CGenMissionRow;
  };
  vehicleDrop?: {
    voi: CGenVoitureRow;
    CMIS: MissionCMI;
  };
  passengerNone?: { mission: CGenMissionRow };
  passengerPickup?: Array<CGenPresenceRow>;
  passengerDrop?: Array<CGenPresenceRow>;
  roadSheet?: MissionRoadSheet;
}

export interface MissionStep {
  separator?: {
    estimation: {
      distance: string;
      time: string;
    };
  };
  startTime?: Date;
  viewType?: {
    condensed?: boolean;
    detail?: boolean;
    dashboard?: boolean;
  };
  active?: boolean;
  blocs?: MissionBloc;
}

export interface MissionSynteseEquipement {
  list?: [
    {
      label: string;
      count: number;
    }?,
  ];
  total: number;
}

export interface MissionCMI {
  passengers?: MissionSynteseEquipement;
  baggage?: MissionSynteseEquipement;
  equipment?: MissionSynteseEquipement;
}

export interface MisCmiJson {
  NB_ADULTE?: number | string;
  NB_BEBE?: number | string;
  NB_ENFANT?: number | string;
  NB_GUIDE?: number | string;
  NB_HANDICAPE_ASSISTE?: number | string;
  NB_HANDICAPE_NON_ASSISTE?: number | string;
  NB_GRAND_SAC?: number | string;
  NBRE_BAGAGE_SOUTE?: number | string;
  NBRE_BAGAGE_CABINE?: number | string;
  NB_HANDICAPE_FAUTEUIL?: number | string;
  NB_SIEGE_AUTO?: number | string;
  NB_SIEGE_BEBE?: number | string;
  NB_SIEGE_REHAUSSEUR?: number | string;
}

export enum MissionViewType {
  DASHBOARD = 'DASHBOARD',
  DETAIL = 'DETAIL',
  CONDENSED = 'CONDENSED',
  DTR = 'DTR',
  DTR_DETAIL = 'DTR_DETAIL',
}

export enum MissionState {
  PREPARATION = 'PREPARATION',
  ONGOING = 'ONGOING',
  POST = 'POST',
}
