// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  margin-bottom: 0.66rem;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px var(--way-color-m2) solid;
}
.input-wrapper remix-icon {
  margin-right: 0.9em;
  margin-bottom: 0;
  fill: var(--color-light-gray);
}
.input-wrapper ion-input {
  color: var(--way-color-d1);
  font-weight: 500;
  font-size: 1rem;
  background: transparent !important;
  box-shadow: none;
  padding: 0;
  width: 100%;
  --padding-start: 0 !important;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-search/way-search.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,4CAAA;AACF;AACE;EACE,mBAAA;EACA,gBAAA;EACA,6BAAA;AACJ;AAEE;EACE,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,kCAAA;EACA,gBAAA;EACA,UAAA;EACA,WAAA;EACA,6BAAA;AAAJ","sourcesContent":[":host {\n  width: 100%;\n  margin-bottom: 0.66rem;\n}\n\n.input-wrapper {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  border-bottom: 1px var(--way-color-m2) solid;\n\n  remix-icon {\n    margin-right: 0.9em;\n    margin-bottom: 0;\n    fill: var(--color-light-gray);\n  }\n\n  ion-input {\n    color: var(--way-color-d1);\n    font-weight: 500;\n    font-size: 1rem;\n    background: transparent !important;\n    box-shadow: none;\n    padding: 0;\n    width: 100%;\n    --padding-start: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
