// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `remix-icon {
  opacity: 0.7;
  transition: opacity 0.3s;
}
remix-icon:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/copy/copy.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,wBAAA;AACF;AACE;EACE,UAAA;AACJ","sourcesContent":["remix-icon {\n  opacity: 0.7;\n  transition: opacity 0.3s;\n\n  &:hover {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
