// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtitle {
  color: var(--ion-color-way-dark);
  margin-bottom: 13px;
  font-size: 16px;
}

.rectangle {
  background: #d0d4e6;
  border-radius: 4px;
  height: 18px;
  width: 3px;
  margin: 3px 5px 0 0;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/subtitle/subtitle.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;AACF","sourcesContent":[".subtitle {\n  color: var(--ion-color-way-dark);\n  margin-bottom: 13px;\n  font-size: 16px;\n}\n\n.rectangle {\n  background: #d0d4e6;\n  border-radius: 4px;\n  height: 18px;\n  width: 3px;\n  margin: 3px 5px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
