import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
// import { NGXLogger } from 'ngx-logger';
import { Controller } from '@way-lib-jaf/controller';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { Gds } from '@way-lib-jaf/gds';
import { AuthService } from '@way-lib/common/services/auth/auth.service';
import { LangService } from '@way-lib/ui/lang.service'

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import packageApp from '../../package.json';
import { MenuService } from './services/menu.service';

@Component({
  selector       : 'app-root',
  templateUrl    : 'app.component.html',
  styleUrls      : ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends Controller implements OnInit {
  name: string = packageApp.name;

  version: string = packageApp.version;

  gdsName: string = environment.name;

  protected actionLoadData: string = '/gdsv3/get-data-chauffeur';

  public disableMenu = false;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

  constructor(
    protected cm: ConceptManager,
    // protected logger: NGXLogger,
    protected gds: Gds,
    public menuService: MenuService,
    protected translate: TranslateService,
    public authService: AuthService,
    public langService: LangService,
    @Inject('WaySuffix') public suffix: string,
  ) {
    super(cm, gds, translate, authService, langService);
  }
}
