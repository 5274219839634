// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title {
  padding-inline-start: 0;
  padding-inline-end: 0;
  text-align: start;
  margin: 2.3em 0 2em 0.8em;
}

ion-item ion-label {
  font-weight: 500;
  color: var(--way-style-txt-tertiary);
  text-transform: uppercase;
  font-size: 1.1rem;
}

remix-icon {
  fill: var(--way-style-txt-tertiary);
}

@media (prefers-color-scheme: dark) {
  ion-item ion-label {
    color: var(--ion-color-white_waynium);
  }
}
ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.menu-icon {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

ion-badge {
  position: absolute;
  top: -10px;
  left: 13px;
  opacity: 1;
  font-size: 13px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  padding: 3px 0px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-navigation/way-navigation.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,qBAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,oCAAA;EACA,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA;EACE;IACE,qCAAA;EACF;AACF;AAEA;EACE,qBAAA;EACA,eAAA;EAEA,oCAAA;AADF;;AAIA;EACE,iCAAA;AADF;;AAIA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,UAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBAAA;AADF","sourcesContent":["ion-title {\n  padding-inline-start: 0;\n  padding-inline-end: 0;\n  text-align: start;\n  margin: 2.3em 0 2em 0.8em;\n}\n\nion-item ion-label {\n  font-weight: 500;\n  color: var(--way-style-txt-tertiary);\n  text-transform: uppercase;\n  font-size: 1.1rem;\n}\n\nremix-icon {\n  fill: var(--way-style-txt-tertiary);\n}\n\n@media (prefers-color-scheme: dark) {\n  ion-item ion-label {\n    color: var(--ion-color-white_waynium);\n  }\n}\n\nion-note {\n  display: inline-block;\n  font-size: 16px;\n\n  color: var(--ion-color-medium-shade);\n}\n\nion-item.selected {\n  --color: var(--ion-color-primary);\n}\n\n.menu-icon {\n  position: relative;\n  display: flex;\n  align-items: center;\n  gap: 1.6rem\n}\n\nion-badge {\n  position: absolute;\n  top: -10px;\n  left: 13px;\n  opacity: 1;\n  font-size: 13px;\n  font-weight: bold;\n  width: 20px;\n  height: 20px;\n  border-radius: 10px;\n  text-align: center;\n  padding: 3px 0px;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
