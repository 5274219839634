import { formatDate } from '@angular/common';
import * as dayjs from 'dayjs';

export class Jaf {
  public static LAN_CODE;

  public static currencySymbol;

  public static currencyDecimal;

  public static dico: string[][] = [];

  public static nameStorage = 'local';

  public static appType = 'wayd';

  public static getTable(lc, rowset) {
    const nbCarMax = 20;
    let res        = '';
    lc.forEach((nc) => {
      res += `  |  ${Jaf.formatValue_chaine(nc, nbCarMax, true)}`;
    });
    res += '\n';
    rowset.forEach((row) => {
      lc.forEach((nc) => {
        res += `  |  ${Jaf.formatValue_chaine(row[nc], nbCarMax, true)}`;
      });
      res += '\n';
    });
    return res;
  }

  public static getDistanceKm(latA, lngA, latB, lngB) {
    if ((latA === 0 && lngA === 0) || (latB === 0 && lngB === 0)) {
      return -1;
    }
    if (latA === latB && lngA === lngB) {
      return 0;
    }
    const t =
      Math.sin(latA * 0.01745329) * Math.sin(latB * 0.01745329) +
      Math.cos(latA * 0.01745329) *
        Math.cos(latB * 0.01745329) *
        Math.cos(lngA * 0.01745329 - lngB * 0.01745329);
    return (Math.atan(-t / Math.sqrt(-t * t + 1)) + 2 * Math.atan(1)) * 6366.8329;
  }

  public static getDistanceRouteByDistance(distance) {
    if (distance < 1) {
      return 2 * distance;
    }
    if (distance < 3) {
      return 1.6 * distance;
    }
    if (distance > 20) {
      return 1.2 * distance;
    }
    return distance * (1.6 - (0.4 * (distance - 3)) / 17);
  }

  public static getDistanceByTemps(t) {
    const temps = t / 60000;
    if (temps < 12.5) {
      return temps * 0.4;
    }
    if (temps < 14.3) {
      return temps * (0.7 - (0.3 * (14.3 - temps)) / 2.2);
    }
    if (temps < 20) {
      return temps * (1 - (0.3 * (20 - temps)) / 5.7);
    }
    if (temps < 23) {
      return temps * (1.3 - (0.3 * (23 - temps)) / 3);
    }
    return temps * 1.3;
  }

  public static getTempsByDistance(distance) {
    if (distance < 5) {
      return (distance / 0.4) * 60000;
    }
    if (distance < 10) {
      return (distance / (0.7 - 0.3 * ((10 - distance) / 5))) * 60000;
    }
    if (distance < 20) {
      return (distance / (1 - 0.3 * ((20 - distance) / 10))) * 60000;
    }
    if (distance < 30) {
      return (distance / (1.3 - 0.3 * ((30 - distance) / 10))) * 60000;
    }
    return (distance / 1.3) * 60000;
  }

  public static formatValue_formatNombre(valeur, decimal, separateur) {
    let val  = Math.floor(Math.abs(valeur));
    let deci = Math.round(10 ** decimal * (Math.abs(valeur) - val));
    if (decimal === 0 || deci === 10 ** decimal) {
      val  = Math.floor(Math.abs(valeur));
      deci = 0;
    }
    let valFormat = `${val}`;
    const nb      = valFormat.length;
    for (let i = 1; i < 4; i += 1) {
      if (val >= 10 ** (3 * i)) {
        valFormat =
          valFormat.substring(0, nb - 3 * i) + separateur + valFormat.substring(nb - 3 * i);
      }
    }
    if (decimal > 0) {
      let decim = '';
      for (let j = 0; j < decimal - deci.toString().length; j += 1) {
        decim += '0';
      }
      deci      = +(decim + deci.toString());
      valFormat = `${valFormat}.${deci}`;
    }
    if (parseFloat(valeur) < 0) {
      valFormat = `-${valFormat}`;
    }
    return valFormat;
  }

  public static isADateTime(value) {
    if (value === undefined || value === null) return false;
    if (typeof value !== 'object') return false;
    return value.getHours() !== 0 || value.getMinutes() !== 1 || value.getSeconds() !== 11;
  }

  public static formatValue_Montant(value, symbol = this.currencySymbol) {
    if (!value) {
      return '';
    }
    switch (symbol) {
      case '$':
        return symbol + this.formatValue_formatNombre(value, this.currencyDecimal, ',');
      default:
        return `${this.formatValue_formatNombre(value, this.currencyDecimal, ' ')} ${symbol}`;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static formatValue_Pourcentage(value) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static formatValue_Temps(value) {}

  public static formatValue_date(value) {
    return formatDate(value, 'shortDate', this.LAN_CODE);
  }

  public static formatValue_chaine(value, nbCar, flagCenter) {
    let chaine = `${value}`;
    if (chaine.length > nbCar) chaine = `${chaine.substr(0, nbCar - 3)}...`;
    const nbCarChaine = chaine.length;
    const nbEspace1   = Math.round((nbCar - nbCarChaine) / 2);
    const nbEspace2   = nbCar - nbEspace1 - nbCarChaine;
    if (flagCenter) {
      return ' '.repeat(nbEspace1) + chaine + ' '.repeat(nbEspace2);
    }
    return chaine + ' '.repeat(nbEspace1 + nbEspace2);
  }

  public static translate(chaine, toLanCode?: string) {
    if (chaine) {
      if (chaine.substr(0, 1) === '%' && chaine.substr(chaine.length - 1, 1) === '%') {
        return this.translate(chaine.substr(1, chaine.length - 2));
      }
      if (chaine.indexOf('~^') > 0) {
        const tmp     = chaine.split('~^');
        const lanCode = toLanCode
          ? toLanCode.substr(0, 2).toUpperCase()
          : this.LAN_CODE.substr(0, 2).toUpperCase();
        for (let i = 1; i < tmp.length; i += 2) {
          if (tmp[i] === lanCode) {
            return tmp[i - 1];
          }
        }
        return 'Erreur de traduction';
      }
      return chaine;
    }
    return '';
  }

  public static comparePureTexte(s1, s2) {
    const s1Tmp = s1 ? this.onlyAZ(s1) : '';
    return s2 ? s1Tmp.indexOf(this.onlyAZ(s2)) > -1 : false;
  }

  public static compareTexte(s1, s2) {
    const s1Tmp = s1 ? this.toUpperCaseSansAccent(s1) : '';
    return s2 ? s1Tmp.indexOf(this.toUpperCaseSansAccent(s2)) > -1 : false;
  }

  public static onlyAZ(str) {
    if (str) {
      const accent   = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g, // C, c
      ];
      const noaccent = ['A', 'A', 'E', 'E', 'I', 'I', 'O', 'O', 'U', 'U', 'N', 'N', 'C', 'C'];
      let tmpStr     = str;
      for (let i = 0; i < accent.length; i += 1) {
        tmpStr = tmpStr.replace(accent[i], noaccent[i]);
      }
      let res = '';
      tmpStr  = tmpStr.toUpperCase();
      Object.keys(str).forEach((i) => {
        const nc = str[i].charCodeAt(0);
        if (nc >= 65 && nc <= 90) {
          res += str[i];
        }
      });
      return res;
    }
    return '';
  }

  public static toUpperCaseSansAccent(str) {
    if (str) {
      const accent: RegExp[] = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g, // C, c
      ];
      const noaccent         = ['A', 'A', 'E', 'E', 'I', 'I', 'O', 'O', 'U', 'U', 'N', 'N', 'C', 'C'];
      let tmpStr             = str;
      for (let i = 0; i < accent.length; i += 1) {
        tmpStr = tmpStr.replace(accent[i], noaccent[i]);
      }
      return tmpStr.toUpperCase();
    }
    return '';
  }

  public static parse(value) {
    if (typeof value === 'object') return value;
    if (typeof value === 'string') {
      if (value.length > 0) {
        if (value.substr(0, 1) === '[' || value.substr(0, 1) === '{') {
          return JSON.parse(value);
        }
      }
    }
    return value;
  }

  public static getTimeWithSeconds(t: string): string {
    if (!t) {
      return '00:00:00';
    }
    if (t.match(/^([0-9]{2}):([0-9]{2})$/)) {
      return `${t}:00`;
    }
    if (t.match(/^([0-9]{2})$/)) {
      return `${t}:00:00`;
    }
    return t;
  }

  public static time2mysql(d) {
    /**
     * Retourne le time au format mysql
     */
    if (!d) {
      return null;
    }

    if (typeof d === 'string') {
      if (d.length > 8) {
        const d2 = new Date(d);
        if (d2) {
          return formatDate(d2, 'HH:mm:ss', this.LAN_CODE);
        }
        return null;
      }
      if (
        d.match(/^([0-9]{2}):([0-9]{2}):([0-9]{2})$/) ||
        d.match(/^([0-9]{2}):([0-9]{2})$/) ||
        d.match(/^([0-9]{2})([0-9]{2})$/)
      ) {
        return d;
      }
    }
    return formatDate(this.getDate(d), 'HH:mm:ss', this.LAN_CODE);
  }

  public static date2mysql(d) {
    /**
     * Retourne la date au format mysql
     */
    if (d === '?') {
      return d;
    }
    return d ? formatDate(this.getDate(d), 'yyyy-MM-dd', this.LAN_CODE) : null;
  }

  public static date2mysqltime(d) {
    if (d instanceof Date) {
      return formatDate(this.getDate(d), 'yyyy-MM-dd HH:mm:ss', this.LAN_CODE);
    }
    return `format non reconnu=${d}`;
  }

  public static mysql2date(d) {
    /**
     * Retourne la date selon la locale
     */
    return formatDate(this.getDate(d), 'shortDate', this.LAN_CODE);
  }

  /**
   *
   * @param d Date as string
   * @returns 'Shortened readable date using locale. Ex: 17 juil.'
   */
  public static getShortReadableDate(d: string): string {
    if (!d) return '';
    const date   = new Date(d);
    const format = 'd MMM';
    return formatDate(date, format, this.LAN_CODE);
  }

  public static getTimeWithoutSeconds(time: string): string {
    if (!time) return '';
    return time.slice(0, 5);
  }

  public static getDateSansHeure(d) {
    return this.getDate(`${d} 00:01:11`);
  }

  public static getDateNew(chaine) {
    return dayjs(chaine, 'MM-DD-YYYY');
  }

  public static getDate(chaine): Date {
    /**
     * Retourne un object Date depuis différents formats de lecture
     */
    if (!chaine) {
      return null;
    }

    if (chaine === '?') {
      return chaine;
    }

    if (chaine instanceof Date) {
      return chaine;
    }

    if (typeof chaine === 'number') {
      return new Date(chaine);
    }

    if (chaine.length === 10) {
      const tab = chaine.match(/^([0-9]{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      if (tab && tab.length === 4) {
        // 2013-01-31
        return new Date(+tab[1], +tab[2] - 1, +tab[3], 0, 0, 0);
      }
    }

    if (chaine.length === 16) {
      const tab = chaine.match(
        /^([0-9]{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) ([0-5][0-9]):([0-5][0-9])$/,
      );
      if (tab) {
        // 2013-01-31 15:30
        return new Date(+tab[1], +tab[2] - 1, +tab[3], +tab[4], +tab[5]);
      }
    }

    if (chaine.length === 19) {
      const tab = chaine.match(
        /^([0-9]{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) ([0-5][0-9]):([0-5][0-9]):([0-5][0-9])$/,
      );
      if (tab) {
        // 2013-01-31 15:30:00
        return new Date(+tab[1], +tab[2] - 1, +tab[3], +tab[4], +tab[5], +tab[6]);
      }
    }

    if (chaine.length === 24) {
      const tab = chaine.match(
        /^([0-9]{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([0-5][0-9]):([0-5][0-9]):([0-5][0-9]).([0-9]{3})Z/,
      );
      if (tab) {
        // 2021-03-23T11:12:23.000Z
        return new Date(+tab[1], +tab[2] - 1, +tab[3], +tab[4], +tab[5], +tab[6]);
      }
    }

    if (chaine.length === 25) {
      const tab = chaine.match(
        /^([0-9]{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([0-5][0-9]):([0-5][0-9]):([0-5][0-9])\+([0-5][0-9]):([0-5][0-9])/,
      );
      if (tab) {
        // 2021-01-01T00:00:00+01:00
        return new Date(+tab[1], +tab[2] - 1, +tab[3], +tab[4], +tab[5], +tab[6]);
      }
    }

    if (chaine.length === 29) {
      const tab = chaine.match(
        /^([0-9]{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([0-5][0-9]):([0-5][0-9]):([0-5][0-9]).([0-9]{3})\+([0-5][0-9]):([0-5][0-9])/,
      );
      if (tab) {
        // 2021-10-22T11:17:11.762+02:00
        return new Date(+tab[1], +tab[2] - 1, +tab[3], +tab[4], +tab[5], +tab[6]);
      }
    }
    return null;
  }

  public static getTemps(value) {
    if (!value) {
      return -1;
    }
    if (value.indexOf('h') > 0) {
      const tab = value.split('h');
      return tab.length === 2 ? 1 * tab[0] * 3600 + 1 * tab[1] * 60 : 0;
    }
    if (value.indexOf(':') > 0) {
      const tab = value.split(':');
      return 1 * tab[0] * 3600 + 1 * tab[1] * 60 + (tab[2] > 0 ? 1 * tab[2] : 0);
    }
    const v = value * 1;
    if (v > 100) {
      return this.getTemps(
        `${value.substr(0, value.length - 2)}:${value.substr(value.length - 2)}`,
      );
    }
    return typeof v === 'number' ? Math.min(v * 3600, 24 * 3600 - 1) : -1;
  }

  public static loadDictionnaire(lanCode, dictionnaire) {
    const lanCodeTmp = lanCode.substr(0, 2).toUpperCase();
    if (!this.dico[lanCodeTmp]) {
      this.dico[lanCodeTmp] = dictionnaire;
    } else {
      this.dico[lanCodeTmp] = Object.assign(this.dico[lanCodeTmp], dictionnaire);
    }
  }

  /* cette fonction prend un array et une string (tris), permet de trier rowset
     en allant chercher les valeurs de chaque propieté de l'objet qu'il contient
     pour les triers. Plusieurs proprieté sont acceptées
    par exemple Jaf.sortSpecial(rowset,'EPR_TRI asc,EPR_TRI2 desc')
   */
  public static sortSpecial(rowset, tris) {
    if (tris && rowset.length > 1) {
      if (tris.indexOf(',') > -1) {
        const lt  = tris.split(',');
        const tri = [];
        Object.keys(lt).forEach((i) => {
          if (i) {
            const ll   = lt[i].split(' ');
            const sens = ll[1] && ll[1].toLowerCase() === 'desc' ? 0 : 1;
            tri.push([ll[0], sens]);
          }
        });

        rowset.sort((a, b) => {
          let score = 0;
          Object.keys(tri).forEach((i) => {
            if (i) {
              const key = tri[i][0];
              if (Number.isNaN(a[key])) {
                if (a[key] !== b[key]) {
                  score = (tri[i][1] ? 1 : -1) * (a[key] > b[key] ? 1 : -1);
                }
              } else if (a[key] !== b[key]) {
                score = tri[i][1] ? a[key] - b[key] : b[key] - a[key];
              }
            }
          });
          return score;
        });
      } else {
        let sens        = true;
        let triModifier = tris;
        if (tris.includes(' ')) {
          const triLower = tris.toLowerCase();
          if (triLower.substr(triLower.length - 4, 4) === 'desc') {
            sens        = false;
            triModifier = tris.substr(0, tris.length - 5);
          } else {
            triModifier = tris.substr(0, tris.length - 4);
          }
        }

        if (Number.isNaN(rowset[0][triModifier])) {
          rowset.sort(
            (a, b) =>
              (sens ? 1 : -1) *
              (a[triModifier] > b[triModifier] ? 1 : -1) *
              (a[triModifier] === b[triModifier] ? 0 : -1),
          );
        } else {
          rowset.sort((a, b) =>
            sens ? a[triModifier] - b[triModifier] : b[triModifier] - a[triModifier],
          );
        }
      }
    }
  }

  public static getLangIdFromIso(countryIso: string): string {
    switch (countryIso) {
      case 'FR':
        return '1';
      case 'GB':
        return '2';
      case 'US':
        return '2';
      default:
        return '1';
    }
  }

  public static subtractHours(time1: string, time2: string): string {
    if (!Jaf.isValidTimeFormat(time1) || !Jaf.isValidTimeFormat(time2)) {
      return '';
    }

    let diffSeconds = Jaf.timeToSeconds(time1) - Jaf.timeToSeconds(time2);

    if (diffSeconds < 0) {
      diffSeconds += 24 * 3600;
    }

    return Jaf.secondsToTime(diffSeconds);
  }

  public static computeHours(time1: string, time2: string): string {
    if (!Jaf.isValidTimeFormat(time1) || !Jaf.isValidTimeFormat(time2)) {
      throw new Error('Invalid time format. Expected format: HH:MM:SS');
    }

    const totalSeconds = Jaf.timeToSeconds(time1) + Jaf.timeToSeconds(time2);
    return Jaf.secondsToTime(totalSeconds);
  }

  public static secondsToTime(totalSeconds: number): string {
    if (!Number.isInteger(totalSeconds) || totalSeconds < 0) {
      throw new Error('Invalid input: totalSeconds must be a non-negative integer');
    }

    const hours   = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return [
      String(hours).padStart(2, '0'),
      String(minutes).padStart(2, '0'),
      String(seconds).padStart(2, '0'),
    ].join(':');
  }

  public static timeToSeconds(time: string): number {
    if (!Jaf.isValidTimeFormat(time)) {
      throw new Error('Invalid time format. Expected format: HH:MM:SS');
    }

    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  private static isValidTimeFormat(time: string): boolean {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  }

  public static capitalize(str: string, firstOnly = false): string {
    if(!str) return ''
    
    return str
      .split(' ')
      .map((word, i) => {
        return firstOnly && i !== 0 ? word : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      })
      .join(' ');
  }

  public static formatShortDate(date: Date, showYear: boolean = true) {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day    : 'numeric',
      month  : 'short',
    };

    if (showYear) {
      options.year = 'numeric';
    }

    return Jaf.capitalize(new Intl.DateTimeFormat(Jaf.LAN_CODE, options).format(date));
  }

  public static roundCurrency(num: number): string {
    return (Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2);
  }
}
