import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector   : 'way-flag',
  templateUrl: './flag.component.html',
  styleUrls  : ['./flag.component.scss'],
})
export class FlagComponent implements OnChanges {
  @Input() lanId: number | string;

  @Input() countryISO2: string;

  @Input() variant: 'normal' | 'secondary' = 'normal';

  flagUrl;

  constructor(private httpClient: HttpClient) {}

  private languageMap: Record<number, string> = {
    12: 'EN',
    11: 'RU',
    10: 'NL',
    9 : 'DE',
    8 : 'JP',
    7 : 'IT',
    6 : 'TH',
    5 : 'CN',
    4 : 'ES',
    3 : 'PT',
    2 : 'US',
    1 : 'FR',
  };

  get lanCode(): string {
    if (!this.lanId) return this.countryISO2;

    return this.languageMap[this.lanId];
  }

  ngOnChanges() {
    if (this.lanCode) {
      this.flagUrl = this.getFlagUrl(this.lanCode);
    }
  }

  getFlagUrl(lanCode: string) {
    const file = `assets/flag/flag_${lanCode}.gif`;
    if (this.fileExists(file)) {
      return file;
    }
    return null;
  }

  fileExists(url: string): Observable<boolean> {
    return this.httpClient.get(url).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }
}
