// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
}
:host ion-text {
  font-size: 1.05rem;
  font-weight: 500;
}
:host .title {
  color: var(--way-color-d2);
  font-weight: 500;
  font-size: 1.05rem;
}
:host .subtitle {
  color: var(--way-color-d1);
  font-weight: 400;
  font-size: 1.05rem;
}

.licence-plate {
  margin-left: auto;
  text-align: center;
  min-width: 80px;
  height: 20px;
  border-radius: 3px;
  border-left: 2px solid var(--way-style-brand);
  border-right: 2px solid var(--way-style-brand);
  border-top: 1px solid var(--way-style--block-border);
  border-bottom: 1px solid var(--way-style-block-border);
  padding: 2px 3px;
  font-size: 0.85em;
  font-weight: 600;
  background: var(--way-color-l1);
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/vehicle/vehicle.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,QAAA;AACF;AACE;EACE,kBAAA;EACA,gBAAA;AACJ;AAEE;EACE,0BAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,0BAAA;EACA,gBAAA;EACA,kBAAA;AADJ;;AAKA;EACE,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,6CAAA;EACA,8CAAA;EACA,oDAAA;EACA,sDAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,+BAAA;AAFF","sourcesContent":[":host {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  gap: 4px;\n\n  ion-text {\n    font-size: 1.05rem;\n    font-weight: 500;\n  }\n\n  .title {\n    color: var(--way-color-d2);\n    font-weight: 500;\n    font-size: 1.05rem;\n  }\n\n  .subtitle {\n    color: var(--way-color-d1);\n    font-weight: 400;\n    font-size: 1.05rem;\n  }\n}\n\n.licence-plate {\n  margin-left: auto;\n  text-align: center;\n  min-width: 80px;\n  height: 20px;\n  border-radius: 3px;\n  border-left: 2px solid var(--way-style-brand);\n  border-right: 2px solid var(--way-style-brand);\n  border-top: 1px solid var(--way-style--block-border);\n  border-bottom: 1px solid var(--way-style-block-border);\n  padding: 2px 3px;\n  font-size: 0.85em;\n  font-weight: 600;\n  background: var(--way-color-l1);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
