// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --color-checked: var(--ion-color-primary, #black);
  border-bottom: 1px solid var(--way-style-page-border);
  background: transparent;
}
:host ion-segment {
  --background: var(--way-color-l1) !important;
  z-index: 1;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.0823529412);
}
:host ion-segment ion-segment-button {
  --indicator-height: 3px;
  height: 48px;
  min-height: 48px;
}
:host ion-segment ion-segment-button ion-label {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--way-color-d1);
  text-transform: none;
  letter-spacing: initial;
  overflow: visible;
}
:host ion-segment ion-segment-button ion-badge {
  position: absolute;
  top: 0;
  right: 0.5rem;
  opacity: 1;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  padding: 3px 0px;
  box-sizing: border-box;
}
:host ion-segment ion-segment-button::part(indicator-background) {
  margin: auto;
  width: 74%;
  border-radius: 30px;
}
:host ion-segment ion-label {
  color: var(--color-checked);
}`, "",{"version":3,"sources":["webpack://./projects/way-lib/src/lib/common/components/way-segment-nav/way-segment-nav.component.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;EACA,qDAAA;EACA,uBAAA;AACF;AACE;EACE,4CAAA;EACA,UAAA;EACA,mDAAA;AACJ;AACI;EACE,uBAAA;EACA,YAAA;EACA,gBAAA;AACN;AACM;EACE,gBAAA;EACA,gBAAA;EACA,0BAAA;EACA,oBAAA;EACA,uBAAA;EACA,iBAAA;AACR;AAEM;EACE,kBAAA;EACA,MAAA;EACA,aAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBAAA;AAAR;AAGM;EACE,YAAA;EACA,UAAA;EACA,mBAAA;AADR;AAKI;EACE,2BAAA;AAHN","sourcesContent":[":host {\n  --color-checked: var(--ion-color-primary, #black);\n  border-bottom: 1px solid var(--way-style-page-border);\n  background: transparent;\n\n  ion-segment {\n    --background: var(--way-color-l1) !important;\n    z-index: 1;\n    box-shadow: 1px 2px 8px #00000015;\n\n    ion-segment-button {\n      --indicator-height: 3px;\n      height: 48px;\n      min-height: 48px;\n\n      ion-label {\n        font-size: 0.8em;\n        font-weight: 500;\n        color: var(--way-color-d1);\n        text-transform: none;\n        letter-spacing: initial;\n        overflow: visible;\n      }\n\n      ion-badge {\n        position: absolute;\n        top: 0;\n        right: 0.5rem;\n        opacity: 1;\n        width: 20px;\n        height: 20px;\n        border-radius: 10px;\n        text-align: center;\n        padding: 3px 0px;\n        box-sizing: border-box;\n      }\n\n      &::part(indicator-background) {\n        margin: auto;\n        width: 74%;\n        border-radius: 30px;\n      }\n    }\n\n    ion-label {\n      color: var(--color-checked);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
