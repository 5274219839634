import { JafConcept } from '@way-lib-jaf/concept';
import { CGenVoitureRow } from './CGenVoitureRow';

export class CGenVoiture extends JafConcept {
  public primary = 'VOI_ID';

  protected name = 'nf_gen_voiture';

  protected class = 'C_Gen_Voiture';

  protected rowClass = 'CGenVoitureRow';

  protected trigramme = 'VOI';

  get uniqueList(): CGenVoitureRow[] {
    const uniqueVehicleMap: Map<string, CGenVoitureRow> = new Map();

    const getFilledProperties = (vehicle: CGenVoitureRow): number =>
      Object.values(vehicle).filter(Boolean).length;

    this.all.forEach((vehicle: CGenVoitureRow) => {
      const existing = uniqueVehicleMap.get(vehicle.VOI_LIBELLE);

      if (!existing || getFilledProperties(vehicle) > getFilledProperties(existing)) {
        uniqueVehicleMap.set(vehicle.VOI_LIBELLE, vehicle);
      }
    });

    return Array.from(uniqueVehicleMap.values());
  }
}
